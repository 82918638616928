import { useState } from "react";

interface ApiUploadParams {
  maxCount: number;
}

type UseApiUploadType = {
  (uploadParams: ApiUploadParams): {
    uploadedFileIds: string[];
    beforeUpload: (_file: any, files: any[]) => boolean;
    fileList: any[];
    maxCount: number;
  };
};

// TODO: discuss with team
export const useApiUpload: UseApiUploadType = ({ maxCount }) => {
  const [uploadedFileIds] = useState<string[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);

  const beforeUpload = (_file: any, files: any[]): boolean => {
    const totalFiles = fileList.length;
    const filesCount = files.length;

    if (totalFiles + filesCount > maxCount) {
      const excessFileCount = totalFiles + filesCount - maxCount;
      // convert negative
      const deleteItemCount = excessFileCount - excessFileCount * 2;
      files.splice(deleteItemCount);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setFileList([...fileList, ...files]);

    return true;
  };

  return {
    uploadedFileIds,
    beforeUpload,
    fileList,
    maxCount,
  };
};
