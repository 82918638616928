import {
  Col,
  EditButton,
  List,
  Modal,
  NumberField,
  RefreshButton,
  Row,
  Show,
  ShowButton,
  Space,
  Table,
  Tag,
  Typography,
  useDrawerForm,
  useTable,
} from "@pankod/refine-antd";
import { useApiUrl, useShow, useTranslate } from "@pankod/refine-core";
import {
  CreateTechnicalDatasheet,
  EditTechnicalDatasheet,
} from "components/technicaldatasheets";
import { TechnicalDatasheetStatus } from "components/technicalDatasheetStatus";
import { projectStatus } from "constants/project";
import { IProject } from "interfaces/project";
import { ITechnicalDatasheet } from "interfaces/technicaldatasheet";
import { useState } from "react";
import { amperMenu } from "constants/technicalDatasheet";

export const ProjectTechnicalDatasheetsTable: React.FC<{
  record: IProject;
}> = ({ record }) => {
  const API_URL = useApiUrl();
  const t = useTranslate();
  /*  const { formProps:stepFormProps, gotoStep, current, stepsProps, queryResult } =
      useStepsForm<ITechnicalDatasheet>({ form: formProps?.form, defaultFormValues:formProps.form?.getFieldsValue, resource: "technical_datasheets", });
  */
  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow,
  } = useDrawerForm<ITechnicalDatasheet>({
    action: "create",
    resource: "technical_datasheets",
    redirect: false,
    //defaultFormValues:{ name: "hhhhh"}
  });

  const { Title, Text } = Typography;
  const [visible, setVisible] = useState(false);

  const {
    drawerProps: editDTDrawerProps,
    formProps: editDTFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editDTShow,
  } = useDrawerForm<ITechnicalDatasheet>({
    action: "edit",
    resource: "technical_datasheets",
    redirect: false,
  });

  const {
    drawerProps: showDTDrawerProps,
    formProps: showDTFormProps,
    saveButtonProps: showSaveButtonProps,
    show: showDTShow,
  } = useDrawerForm<ITechnicalDatasheet>({
    action: "clone",
    resource: "technical_datasheets",
    redirect: false,
  });

  //createFormProps.initialValues({ "project": `${API_URL}/projects/${record.id}` })

  const { tableProps: technicalDatasheetTableProps } =
    useTable<ITechnicalDatasheet>({
      resource: "technical_datasheets",
      permanentFilter: [
        {
          field: "project.id",
          operator: "eq",
          value: record.id,
        },
      ],
      syncWithLocation: false,
    });

  const { queryResult, showId, setShowId } = useShow<ITechnicalDatasheet>({
    resource: "technical_datasheets",
  });
  const { data, isLoading } = queryResult;
  const record__ = data?.data;

  return (
    <>
      <List
        title={t("technical_datasheets.technical_datasheets")}
        createButtonProps={{
          onClick: () => {
            createShow();
          },
          disabled:
            record?.status === projectStatus.price_offers.value ||
            record?.status === projectStatus.contracts.value ||
            record?.status === projectStatus.provider_contracts.value ||
            record?.status === projectStatus.tenders.value ||
            record?.status === projectStatus.finished.value ||
            record?.status === projectStatus.rejected.value,
        }}
      >
        <Table {...technicalDatasheetTableProps} rowKey="id">
          <Table.Column
            key="name"
            dataIndex="name"
            title={t("technical_datasheets.fields.name")}
          />

          <Table.Column
            key="podNumber"
            dataIndex="podNumber"
            title={t("technical_datasheets.fields.podNumber")}
          />
          <Table.Column<ITechnicalDatasheet>
            dataIndex="status"
            title={t("technical_datasheets.fields.status")}
            render={(value) => {
              return <TechnicalDatasheetStatus status={value} />;
            }}
          />
          <Table.Column<ITechnicalDatasheet>
            title={t("table.actions")}
            dataIndex="actions"
            render={(_, rec) =>
              record?.status === projectStatus.technical_datasheets.value ? (
                <Space>
                  <EditButton onClick={() => editDTShow(rec.id)}>
                    {t("buttons.edittechnicalDatasheets")}
                  </EditButton>
                </Space>
              ) : (
                <Space>
                  <ShowButton
                    size="small"
                    resourceNameOrRouteName="technical_datasheets"
                    recordItemId={rec.id}
                    onClick={() => {
                      setShowId(rec.id);
                      setVisible(true);
                    }}
                  />
                </Space>
              )
            }
          />
        </Table>
      </List>
      <Modal visible={visible} closable={false} footer={false} width={900}>
        <Show
          isLoading={isLoading}
          pageHeaderProps={{
            onBack: () => setVisible(false),
            extra: <RefreshButton hidden/>
          }}
          resource="technical_datasheets"
          recordItemId={showId}
        >
         <Row gutter={20}>
            <Col xs={24} lg={30}>
              <Row gutter={10}>
                <Col xs={24} lg={12}>
                  <Title level={5}>
                    {t("technical_datasheets.fields.name")}
                  </Title>
                  <Text>{record__?.name}</Text>

                  <Title level={5}>
                    {t("technical_datasheets.fields.addressId")}
                  </Title>
                  <Text>{record__?.address}</Text>

                  <Title level={5}>
                    {t("technical_datasheets.fields.providerPartnerNumber")}
                  </Title>
                  <Text>{record__?.providerPartnerNumber}</Text>

                  <Title level={5}>
                    {t("technical_datasheets.fields.providerContractNumber")}
                  </Title>
                  <Text>{record__?.providerContractNumber}</Text>

                  <Title level={5}>
                    {t("technical_datasheets.fields.status")}
                  </Title>
                  <Text>
                    {t(`technical_datasheets.statusTypes.${record__?.status}`)}
                  </Text>

                  <Title level={5}>
                    {t("technical_datasheets.fields.providerType")}
                  </Title>
                  <Text>
                    <Tag>{record__?.providerType}</Tag>
                  </Text>

                  <Title level={5}>
                    {t("technical_datasheets.fields.podNumber")}
                  </Title>
                  <Text>{record__?.podNumber}</Text>

                  <Title level={5}>
                    {t("technical_datasheets.fields.providerMeasuringClock")}
                  </Title>
                  <NumberField
                    value={record__?.providerMeasuringClock ?? 0}
                    options={{
                      notation: "standard",
                    }}
                  />
                </Col>

                <Col xs={24} lg={12}>
                  <Title level={5}>
                    {t("technical_datasheets.fields.phaseR")}
                  </Title>
                  <Text>
                    {amperMenu.find((el) => el.value === record__?.phaseR)?.label}
                  </Text>

                  <Title level={5}>
                    {t("technical_datasheets.fields.phaseS")}
                  </Title>
                  <Text>
                    {amperMenu.find((el) => el.value === record__?.phaseS)?.label}
                  </Text>

                  <Title level={5}>
                    {t("technical_datasheets.fields.phaseT")}
                  </Title>
                  <Text>
                    {amperMenu.find((el) => el.value === record__?.phaseT)?.label}
                  </Text>

                  <Title level={5}>
                    {t("technical_datasheets.fields.plannedConsumptionkWh")}
                  </Title>
                  <NumberField
                    value={record__?.plannedConsumptionkWh ?? 0}
                    options={{
                      notation: "standard",
                    }}
                  />

                  <Title level={5}>
                    {t("technical_datasheets.fields.yearlyConsumptionFt")}
                  </Title>
                  <NumberField
                    value={record__?.yearlyConsumptionFt ?? 0}
                    options={{
                      notation: "standard",
                    }}
                  />

                  <Title level={5}>
                    {t("technical_datasheets.fields.yearlyConsumptionkWh")}
                  </Title>
                  <NumberField
                    value={record__?.yearlyConsumptionkWh ?? 0}
                    options={{
                      notation: "standard",
                    }}
                  />

                  <Title level={5}>
                    {t("technical_datasheets.fields.plannedConsumptionFt")}
                  </Title>
                  <NumberField
                    value={record__?.plannedConsumptionFt ?? 0}
                    options={{
                      notation: "standard",
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Show>
      </Modal>
      <CreateTechnicalDatasheet
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        //createModalProps={createModalProps}
        saveButtonProps={createSaveButtonProps}
        initialValues={{
          project: `${API_URL}/projects/${record.id}`,
          status: "inProgress",
        }}
      />

      <EditTechnicalDatasheet
        drawerProps={editDTDrawerProps}
        //drawerProps={createDrawerProps}
        formProps={editDTFormProps}
        //editModalProps={editDTModalProps}
        saveButtonProps={editSaveButtonProps} //saveButtonProps={createSaveButtonProps}
      />

      {/*      <EditTechnicalDatasheet
          drawerProps={editModalProps}
          formProps={editFormProps}
          editModalProps={editDrawerProps}
        saveButtonProps={editSaveButtonProps*/}
    </>
  );
};
