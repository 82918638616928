import {
  Show,
  Typography,
  Tag,
  Col,
  Row,
  FileField,
  DateField,
} from "@pankod/refine-antd";
import { useOne, useShow, useTranslate } from "@pankod/refine-core";

const { Title, Text } = Typography;

export const PriceOfferShow = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const t = useTranslate();

  return (
    <Show isLoading={isLoading}>
      <Row gutter={20}>
        <Col xs={24} lg={16}>
          <Row gutter={10}>
            <Col xs={24} lg={12}>
              <Title level={5}>
                {t("technical_datasheets.fields.description")}
              </Title>
              <Text>{record?.description}</Text>

              <Title level={5}>{t("contracts.fields.date")}</Title>
              <DateField value={record?.date} format="LL" />

              <Title level={5}>{t("contracts.fields.dateValid")}</Title>
              <DateField value={record?.validity} format="LL" />

              <Title level={5}>{t("price_offers.fields.status")}</Title>
              <Text>{t(`statusTypes.${record?.status}`)}</Text>

              <Title level={5}>{t("price_offers.fields.file")}</Title>
              {record?.images?.map((data: any) => {
                return (
                  <Col xs={24} lg={12}>
                    <FileField
                      target="_blank"
                      title={data?.name}
                      src={data?.url}
                    />
                  </Col>
                );
              })}
            </Col>
          </Row>
        </Col>
      </Row>
    </Show>
  );
};
