import {
  IResourceComponentsProps,
  useMany,
  useTranslate,
  useApiUrl,
  HttpError,
  CrudFilters,
} from "@pankod/refine-core";

import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  useSelect,
  TagField,
  Select,
  ShowButton,
  BooleanField,
  Radio,
  FilterDropdown,
  getDefaultFilter,
  FilterDropdownProps,
  Card,
  Row,
  Col,
  FormProps,
  Form,
  Input,
  Icons,
  Button,
  useDrawerForm,
  Drawer,
  Create,
} from "@pankod/refine-antd";

import { IPartner, IPartnerFilter } from "interfaces/partner";
import { ILocation } from "interfaces/location";
import { ICountries } from "interfaces/countries";

export const LocationsList: React.FC = () => {
  const API_URL = useApiUrl();
  const t = useTranslate();
  const { formProps, drawerProps, show, saveButtonProps } =
    useDrawerForm<ILocation>({
      action: "create",
      resource: "locations",
      redirect: false
    });

  const {
    selectProps: countrySelectProps,
    queryResult: countryQueryResult,
    defaultValueQueryResult: countryDefaultValueQueryResult,
  } = useSelect<ICountries>({
    resource: "countries",
  });

  const allCountriesOptions = [
    ...(countryQueryResult.data?.data || []),
    ...(countryDefaultValueQueryResult.data?.data || []),
  ];

  const { tableProps: locationTable, sorter } = useTable<
  ILocation,
  HttpError
  >({
    resource: "locations",
    initialSorter: [
      {
        field: "postcode",
        order: "asc",
      },
    ],
  });
  return (
    <>
      <List
        createButtonProps={{children:t("buttons.createLocation"), onClick: () => {
          show();
        },}}
      >
        <Table {...locationTable} rowKey="id">
          <Table.Column
            dataIndex="postcode"
            key="postcode"
            title={t("locations.fields.postcode")}
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("postcode", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="name"
            key="name"
            title={t("locations.fields.name")}
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("name", sorter)}
            sorter
          />

          <Table.Column
            dataIndex="country"
            key="country"
            title={t("locations.fields.country")}
            render={(value) => <TextField value={value.title} />}
            defaultSortOrder={getDefaultSortOrder("country.title", sorter)}
            sorter
          />
        </Table>
      </List>

      <Drawer {...drawerProps}>
        <Create saveButtonProps={saveButtonProps}>
          <Form {...formProps} layout="vertical">
            <Form.Item label={t("locations.fields.postcode")} name="postcode">
              <Input />
            </Form.Item>
            <Form.Item label={t("locations.fields.name")} name="name">
              <Input />
            </Form.Item>

            <Form.Item
              label={t("locations.fields.country")}
              name={["country","id"]}
              rules={[{ required: true, message: "Missing location" }]}
            >
              <Select
                {...countrySelectProps}
                options={allCountriesOptions.map((c) => ({
                  label: `${c.title}`,
                 // value: `${API_URL}/countries/${c.id}`,
                 value: c.id,
                }))}
              />
            </Form.Item>
          </Form>
        </Create>
      </Drawer>
    </>
  );
};
