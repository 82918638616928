import {
  useShow,
  useOne,
  useTranslate,
  useUpdate,
  useApiUrl,
} from "@pankod/refine-core";
import {
  Show,
  Space,
  Typography,
  Tag,
  Col,
  Row,
  PageHeader,
  Button,
  useTable,
  Icons,
  Steps,
  Skeleton,
  Grid,
  useForm,
  Form,
  Input,
  Select,
  Tabs,
  useSelect,
  useDrawerForm,
  useModalForm,
  List,
  Table,
  EditButton,
  DateField,
  notification,
  Progress,
  RefreshButton,
  ListButton,
} from "@pankod/refine-antd";
import { IProject, IEvent } from "interfaces/project";
import dayjs from "dayjs";
import { IProjectEvents } from "interfaces/projectEvents";
import { IPartner } from "interfaces/partner";
import { IUser } from "interfaces/user";
import { ITechnicalDatasheet } from "interfaces/technicaldatasheet";
import {
  CreateTechnicalDatasheet,
  EditTechnicalDatasheet,
} from "components/technicaldatasheets";
import { CreatePriceOffer, EditPriceOffer } from "components/priceOffers";
import { IPriceOffer } from "interfaces/priceoffer";
import { CreateContract, EditContract } from "components/contracts";
import { IContract } from "interfaces/contract";
import { CreateTenders, EditTenders } from "components/tenders";
import { ITenders } from "interfaces/tenders";
import {
  CreateProviderContracts,
  EditProviderContracts,
} from "components/providerContract";
import { IProviderContract } from "interfaces/providercontract";
import { projectStatus } from "constants/project";
import { ProjectTendersTable } from "components/projects/tenders/show";
import { ProjectTechnicalDatasheetsTable } from "components/projects/technicalDatasheets/show";
import { ProjectPriceOffersTable } from "components/projects/priceOffers/show";
import { ProjectContractsTable } from "components/projects/contracts/show";
import { ProjectProviderContractsTable } from "components/projects/providerContracts/show";
import { useEffect, useState } from "react";
import { PartnerSelect } from "components/partner/PartnersSelect";

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

export const ProjectShow = () => {
  const { queryResult: eventQueryResult } = useShow<IProject>({
    resource: "projects",
  });
  const { data, isLoading } = eventQueryResult;
  const { mutate } = useUpdate();
  const record = data?.data;
  const [activeKey, setActiveKey] = useState(record?.status);
  const onTabChange = (activeKey: any) => {
    setActiveKey(activeKey);
  };
  const screens = useBreakpoint();

  const currentBreakPoints = Object.entries(screens)
    .filter((screen) => !!screen[1])
    .map((screen) => screen[0]);

  const t = useTranslate();
  const renderProjectSteps = () => {
    const notFinishedCurrentStep = (event: IProjectEvents, index: number) => {
      return (
        event.status !== projectStatus.rejected.value &&
        event.status !== projectStatus.finished.value &&
        record?.events.findIndex((el) => el.status === record?.status) === index
      );
    };

    const stepStatus = (event: IProjectEvents, index: number) => {
      if (!event.date) return "wait";
      if (event.status === projectStatus.rejected.value) return "error";
      if (notFinishedCurrentStep(event, index)) return "process";
      return "finish";
    };

    const close = () => {
      console.log(
        "Notification was closed. Either the close button was clicked or duration time elapsed."
      );
    };

    return (
      <PageHeader
        className="pageHeader"
        ghost={false}
        title={t("projects.titles.projectStatus")}
        extra={[]}
      >
        <Steps
          direction={
            currentBreakPoints.includes("lg") ? "horizontal" : "vertical"
          }
          current={record?.events.findIndex(
            (el) => el.status === record?.status
          )}
        >
          {record?.events.map((event: IProjectEvents, index: number) => (
            <Steps.Step
              status={stepStatus(event, index)}
              key={index}
              title={
                event?.status
                  ? t(`projects.statuses.${event?.status}`)
                  : t("unknown")
              }
              icon={
                notFinishedCurrentStep(event, index) && (
                  <Progress
                    type="circle"
                    width={40}
                    percent={30}
                    format={(percent) => (
                      <Text italic type="secondary" className="progressTitle">
                        {dayjs(event.date).fromNow()}
                      </Text>
                    )}
                  />
                )
              }
              description={event.date && dayjs(event.date).format("L")}
            />
          ))}
        </Steps>
        {!record && <Skeleton paragraph={{ rows: 1 }} />}
      </PageHeader>
    );
  };

  useEffect(() => {
    if (eventQueryResult.isSuccess && !eventQueryResult.isLoading) {
      setActiveKey(
        record?.status === projectStatus.finished.value ||
          record?.status === projectStatus.rejected.value
          ? projectStatus.technical_datasheets.value
          : record?.status
      );
    }
  }, [
    eventQueryResult.isSuccess,
    eventQueryResult.isRefetching,
    eventQueryResult.isLoading,
  ]);

  return (
    <>
      <Show
        isLoading={isLoading}
        pageHeaderProps={{
          extra: (
            <>
              <RefreshButton hidden />
              <EditButton />
              <ListButton />
            </>
          ),
        }}
      >
        <Row gutter={10}>
          <Space size={20} direction="vertical" style={{ width: "100%" }}>
            {renderProjectSteps()}
          </Space>
        </Row>
        <Row gutter={10}>
          <Col xs={24} lg={19}>
            <Title level={5}>{t("projects.fields.name")}</Title>
            <Text>{record?.name}</Text>
            <Title level={5}></Title>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col xs={24} lg={19}>
            <Title level={5}>{t("projects.fields.agent")}</Title>
            <Text>{`${record?.agent?.lastName} ${record?.agent?.firstName}`}</Text>
            <Title level={5}></Title>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col xs={24} lg={19}>
            {record?.partner?.individual === true ? (
              <Title level={5}>{t("projects.fields.partner")}</Title>
            ) : (
              <Title level={5}>{t("projects.fields.partner")}</Title>
            )}
            <Text>
              <PartnerSelect value={record?.partner} disabled={true} />
            </Text>
            <Title level={5}></Title>
          </Col>
        </Row>
        <Tabs
          defaultActiveKey={`${record?.status}`}
          onChange={onTabChange}
          activeKey={activeKey}
        >
          <Tabs.TabPane
            tab={<span>{t("projects.statuses.technical_datasheets")}</span>}
            key={projectStatus.technical_datasheets.value}
          >
            {record && <ProjectTechnicalDatasheetsTable record={record} />}
          </Tabs.TabPane>

          <Tabs.TabPane
            disabled={
              record?.status === projectStatus.technical_datasheets.value
            }
            tab={<span>{t("price_offers.price_offers")}</span>}
            key={projectStatus.price_offers.value}
          >
            {record && <ProjectPriceOffersTable record={record} />}
          </Tabs.TabPane>

          <Tabs.TabPane
            disabled={
              record?.status === projectStatus.technical_datasheets.value ||
              record?.status === projectStatus.price_offers.value
            }
            tab={<span>{t("contracts.contracts")}</span>}
            key={projectStatus.contracts.value}
          >
            {record && <ProjectContractsTable record={record} />}
          </Tabs.TabPane>

          <Tabs.TabPane
            disabled={
              record?.status === projectStatus.technical_datasheets.value ||
              record?.status === projectStatus.price_offers.value ||
              record?.status === projectStatus.contracts.value
            }
            tab={<span>{t("provider_contracts.provider_contracts")}</span>}
            key={projectStatus.provider_contracts.value}
          >
            {record && <ProjectProviderContractsTable record={record} />}
          </Tabs.TabPane>

          <Tabs.TabPane
            disabled={
              record?.status === projectStatus.technical_datasheets.value ||
              record?.status === projectStatus.price_offers.value ||
              record?.status === projectStatus.contracts.value ||
              record?.status === projectStatus.provider_contracts.value
            }
            tab={<span>{t("tenders.tenders")}</span>}
            key={projectStatus.tenders.value}
          >
            {record && <ProjectTendersTable record={record} />}
          </Tabs.TabPane>
        </Tabs>
      </Show>
    </>
  );
};
