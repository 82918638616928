import { useTranslate, useNavigation } from "@pankod/refine-core";
import {
  Typography,
  Timeline,
  useSimpleList,
  AntdList,
  Tooltip,
  Table,
  Space,
  EditButton,
  ShowButton,
  useTable,
} from "@pankod/refine-antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { IProject } from "interfaces/project";
import "./style.less";

dayjs.extend(relativeTime);

export const OrderTimeline: React.FC = () => {
  const t = useTranslate();
  const { show } = useNavigation();

    const { listProps } = useSimpleList<IProject>({
        resource: "projects"
    });

  const { tableProps: projectTable, sorter } = useTable<IProject>({
    resource: "projects",
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });
  const { dataSource } = listProps;

  const { Text } = Typography;

  const orderStatusColor = (
    id: string
  ): { color: string; text: string } | undefined => {
    switch (id) {
      case "1":
        return { color: "orange", text: "pending" };
      case "2":
        return { color: "cyan", text: "ready" };
      case "3":
        return { color: "green", text: "on the way" };
      case "4":
        return { color: "blue", text: "delivered" };
      case "5":
        return { color: "red", text: "cancelled" };
      default:
        break;
    }
  };

  return (
    <AntdList /*{...listProps}*/>
      <Timeline>
      {/*{dataSource?.map(({ name, id }) => (*/}
        <Table {...projectTable} rowKey="id">
          <Table.Column dataIndex="name" title={t("projects.fields.name")} />
          <Table.Column<IProject>
            title={t("table.actions")}
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            )}
            
          />

        </Table>
        {/*))}*/}
      </Timeline>
    </AntdList>
  );
};
