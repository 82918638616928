import {
  IResourceComponentsProps,
  useMany,
  useTranslate,
  HttpError,
  CrudFilters,
  useShow,
  useCan,
} from "@pankod/refine-core";

import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  useSelect,
  TagField,
  Select,
  ShowButton,
  BooleanField,
  Radio,
  FilterDropdown,
  getDefaultFilter,
  FilterDropdownProps,
  Card,
  Row,
  Col,
  FormProps,
  Form,
  Input,
  Icons,
  Button,
  Modal,
  Show,
  Typography,
  Title,
} from "@pankod/refine-antd";

import { IPartner, IPartnerFilter } from "interfaces/partner";
import { ILocation } from "interfaces/location";
import { useState } from "react";

export const PartnerList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, sorter, filters, searchFormProps } = useTable<
    IPartner,
    HttpError,
    IPartnerFilter
  >({
    resource: "partners",
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],

    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, individual } = params;

      /*      filters.push({
        field: "searchName",
        operator: "eq",
        value: q,
    });

      filters.push({
          field: "lastName",
          operator: "eq",
          value: q,
      });
      */
      filters.push({
        field: "name",
        operator: "eq",
        value: q,
      });

      filters.push({
        field: "individual",
        operator: "eq",
        value: individual,
      });

      return filters;
    },
    syncWithLocation: false,
  });

  const [visible, setVisible] = useState(false);

  const { queryResult, showId, setShowId } = useShow<IPartner>({
    resource: "partners",
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const { Title, Text } = Typography;

  /*const { tableProps : locationTable } = useTable<ILocation>({
    resource: "locations",
});
*/

  const { data: canAccess } = useCan({
    resource: "partners",
    action: "field",
    params: { field: "individual" },
  });

  return (
    <Row gutter={[16, 16]}>
      <Col xl={6} lg={24} xs={24}>
        <Card title={t("partners.titles.filterTitle")}>
          <Filter formProps={searchFormProps} />
        </Card>
      </Col>

      <Col xl={18} xs={24}>
        <List>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="id"
              key="id"
              title="ID"
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("id", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="name"
              key="name"
              title={t("partners.fields.name")}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("name", sorter)}
              sorter
            />

            {canAccess?.can && (
              <Table.Column
                dataIndex="individual"
                key="individual"
                title={t("partners.fields.individual")}
                render={(value) => (
                  <BooleanField
                    value={value}
                    valueLabelFalse={t("partners.fields.individualFalse")}
                    valueLabelTrue={t("partners.fields.individualTrue")}
                  />
                )}
              />
            )}

            <Table.Column<IPartner>
              title={t("table.actions")}
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton
                    size="small"
                    resourceNameOrRouteName="partners"
                    recordItemId={record.id}
                  />
                  <ShowButton
                    size="small"
                    resourceNameOrRouteName="partners"
                    recordItemId={record.id}
                  />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = (props) => {
  const t = useTranslate();
  return (
    <Form layout="vertical" {...props.formProps}>
      <Row gutter={[10, 40]} align="bottom">
        <Col xs={24} xl={24} md={12}>
          <Form.Item label={t("partners.titles.filterSearchLabel")} name="q">
            <Input
              placeholder={t("partners.fields.namePlaceholder")}
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>

        <Col xs={24} xl={24} md={8}>
          <Form.Item
            label={t("partners.titles.filterIndividualLabel")}
            name="individual"
          >
            <Select
              allowClear
              placeholder={t("partners.fields.individualPlaceholder")}
              options={[
                {
                  label: t("partners.fields.individualTrue"),
                  value: true,
                },
                {
                  label: t("partners.fields.individualFalse"),
                  value: false,
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col xs={24} xl={24} md={8}>
          <Form.Item>
            <Button style={{ width: "100%" }} htmlType="submit" type="primary">
              {t("buttons.filter")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
