import { useEffect } from "react";
import {
  useTranslate,
  useApiUrl,
  file2Base64,
  HttpError,
  useGetLocale,
} from "@pankod/refine-core";

import {
  Create,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  ButtonProps,
  Typography,
  Upload,
  Grid,
  getValueFromEvent,
  useSelect,
  SaveButton,
  //DatePicker,
} from "@pankod/refine-antd";
import { DatePicker } from "antd";

import dayjs from "dayjs";
import "dayjs/locale/hu";

import { TOKEN_KEY } from "../../constants";

import {
  normalizeFile,
  mediaUploadMapper,
  getValueProps,
} from "utility/normalize";
import { useApiUpload } from "hooks/upload";
import { statusTypes } from "constants/common";
import moment from "moment";


const { Text } = Typography;

type CreateProviderContractsProps = {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  initialValues: any;
};

export const CreateProviderContracts: React.FC<
  CreateProviderContractsProps
> = ({ drawerProps, formProps, saveButtonProps, initialValues }) => {
  const locale = useGetLocale();
  const { ...uploadProps } = useApiUpload({
    maxCount: 2,
  });
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const breakpoint = Grid.useBreakpoint();

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
    >
      <Create
        resource="provider_contracts"
        actionButtons={
          <>
            <SaveButton {...saveButtonProps} style={{ marginRight: 10 }} />
          </>
        }
      >
        <Form
          encType=""
          {...formProps}
          layout="vertical"
          initialValues={{
            ...initialValues,
          }}
          onFinish={(values) => {
            values = { ...values, images: getValueProps(values?.images, "") };
            return formProps.onFinish?.(mediaUploadMapper(values));
          }}
        >
          <Form.Item
            hidden
            label={t("provider_contracts.fields.project")}
            name="project"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input hidden />
          </Form.Item>

          <Form.Item
            label={t("provider_contracts.fields.description")}
            name="description"
            rules={[
              {
                required: true,
                message: t("defaultValidateMessages.required", {
                  name: "${label}",
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("provider_contracts.fields.status")}
            name="status"
          >
            <Select
              options={Object.values(statusTypes).map((p) => ({
                label: t(`${p.label}`),
                value: p.value,
              }))}
              placeholder={t("buttons.pleaseSelect")}
            />
          </Form.Item>

          <Form.Item
            label={t("provider_contracts.fields.date")}
            name="date"
            rules={[
              {
                required: true,
                message: t("defaultValidateMessages.required", {
                  name: "${label}",
                }),
              },
            ]}
            initialValue={dayjs()}
            getValueProps={(value) => ({
              value: value ? dayjs(value) : dayjs(),
            })}
          >
            <DatePicker placeholder={t("provider_contracts.fields.date")} />
          </Form.Item>

          <Form.Item
            label={t("provider_contracts.fields.requestDate")}
            name="requestDate"
            rules={[
              {
                required: true,
                message: t("defaultValidateMessages.required", {
                  name: "${label}",
                }),
              },
            ]}
            initialValue={dayjs()}
            getValueProps={(value) => ({
              value: value ? dayjs(value) : dayjs(),
            })}
          >
            <DatePicker placeholder={t("provider_contracts.fields.date")} />
          </Form.Item>
          <Form.Item label={t("provider_contracts.fields.file")}>
            <Form.Item
              label={t("provider_contracts.fields.file")}
              name="images"
              normalize={normalizeFile}
              getValueProps={(data) => getValueProps(data, "")}
              valuePropName="fileList"
              noStyle
            >
              <Upload.Dragger
                name="file"
                action={`${apiUrl}/media_objects`}
                headers={{
                  Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
                }}
                listType="picture"
                multiple
                {...uploadProps}
              >
                <p className="ant-upload-text">{t("upload.areaTitle")}</p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
        </Form>
      </Create>
    </Drawer>
  );
};
