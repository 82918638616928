import { useShow, useTranslate } from "@pankod/refine-core";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import {
  useForm,
  Form,
  Input,
  Steps,
  Select,
  Edit,
  useSelect,
  InputNumber,
  useStepsForm,
  Button,
  SaveButton,
  Checkbox,
  Col,
  Row,
  Space,
  getValueFromEvent,
  Show,
  Typography,
  BooleanField,
  Icons,
  EditButton,
  ListButton,
  DeleteButton,
} from "@pankod/refine-antd";
import { Table, Tabs } from "antd";

import { IPartner } from "interfaces/partner";
import { ILocation } from "interfaces/location";
import { publicAreaTypes, addressTypes } from "constants/address";
import { payinghMethod } from "constants/partner";

import { formatStrategyValues } from "rc-tree-select/lib/utils/strategyUtil";
import { IAddress, IAddressType } from "interfaces/address";
import { size } from "lodash";

const { Step } = Steps;

export const PartnerShow: React.FC = () => {
  const {
    formProps,
    saveButtonProps,
    current,
    gotoStep,
    stepsProps,
    queryResult,
  } = useStepsForm<IPartner>();
  const t = useTranslate();

  const { TabPane } = Tabs;

  function callback(key: any) {
    console.log(key);
  }

  const {
    selectProps: locationSelectProps,
    queryResult: locationQueryResult,
    defaultValueQueryResult: locationDefaultValueQueryResult,
  } = useSelect<ILocation>({
    resource: "locations",
  });

  const allOptions = [
    ...(locationQueryResult.data?.data || []),
    ...(locationDefaultValueQueryResult.data?.data || []),
  ];

  const { Title, Text } = Typography;

  const { queryResult: useShowQueryResult } = useShow<IPartner>();
  const { data, isLoading } = useShowQueryResult;
  const record = data?.data;
  const { CloseCircleOutlined, CheckCircleOutlined } = Icons;

  const columns = [
    {
      title: t("addresses.fields.type"),
      dataIndex: "address.type",
      key: "address.type",
      render: (text: any, data: any) =>
        t(`addresses.enum.addressTypes.${data?.type}`),
    },
    {
      title: t("addresses.fields.postcodeShow"),
      dataIndex: "address.locationPostCode",
      key: "address.locationPostCode",
      render: (text: any, data: any) => data?.address.locationPostCode,
    },
    {
      title: t("addresses.fields.location"),
      dataIndex: "address.locationName",
      key: "address.locationName",
      render: (text: any, data: any) => data?.address.locationName,
    },
    {
      title: t("addresses.fields.district"),
      dataIndex: "address.district",
      key: "address.district",
      render: (text: any, data: any) => data?.address.district,
    },
    {
      title: t("addresses.fields.publicAreaName"),
      dataIndex: "address.publicAreaName",
      key: "address.publicAreaName",
      render: (text: any, data: any) => data?.address.publicAreaName,
    },
    {
      title: t("addresses.fields.publicAreaType"),
      dataIndex: "address.publicAreaType",
      key: "address.publicAreaType",
      render: (text: any, data: any) =>
        t(`addresses.enum.publicAreaTypes.${data?.address.publicAreaType}`),
    },

    {
      title: t("addresses.fields.building"),
      dataIndex: "address.building",
      key: "address.building",
      render: (text: any, data: any) => data?.address.building,
    },
    {
      title: t("addresses.fields.houseNumber"),
      dataIndex: "address.houseNumber",
      key: "address.houseNumber",
      render: (text: any, data: any) => data?.address.houseNumber,
    },
    {
      title: t("addresses.fields.staircase"),
      dataIndex: "address.staircase",
      key: "address.staircase",
      render: (text: any, data: any) => data?.address.staircase,
    },
    {
      title: t("addresses.fields.floor"),
      dataIndex: "address.floor",
      key: "address.floor",
      render: (text: any, data: any) => data?.address.floor,
    },
    {
      title: t("addresses.fields.door"),
      dataIndex: "address.door",
      key: "address.door",
      render: (text: any, data: any) => data?.address.door,
    },
  ];

  return (
    <Show isLoading={queryResult?.isFetching}
    pageHeaderProps={{extra: 
    <>
    <ListButton size="middle" hideText/>
    <EditButton size="middle" hideText/>
    <DeleteButton size="middle" hidden/>
    </>}}>
      <Form {...formProps} layout="vertical">
        <>
          <Tabs defaultActiveKey="1" onChange={callback}>
            <TabPane tab={t("partners.titles.personalData")} key="1">
              <Row gutter={20}>
                <Col xs={24} lg={16}>
                  <Row gutter={10}>
                    <Col xs={24} lg={12}>
                      <Title level={5}>{t("partners.fields.name")}</Title>
                      <Text>{record?.name}</Text>

                      <Title level={5}>{t("partners.fields.individual")}</Title>
                      <BooleanField
                        value={record?.individual} 
                        visible={false}
                        trueIcon={
                          <Text>{t("partners.fields.valueLabelTrue")}</Text>
                        }
                        falseIcon={
                          <Text>{t("partners.fields.valueLabelFalse")}</Text>
                        }
                       
                      />
                      <Title level={5}></Title>
                       <Form.Item dependencies={["individual"]}>
                        {({ getFieldValue }) => {
                          return (
                            getFieldValue("individual") && (
                              <>
                                <Title level={5}>
                                  {t("partners.fields.mothersName")}
                                </Title>
                                <Text>{record?.mothersName}</Text>
                              </>
                            )
                          );
                        }}
                      </Form.Item>
                    </Col>

                    <Col xs={24} lg={12}>
                      <Title level={5}>{t("partners.fields.email")}</Title>
                      <Text>{record?.email}</Text>
                      <Title level={5}>
                        {t("partners.fields.phoneNumber")}
                      </Title>
                      <Text>{record?.phoneNumber}</Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab={t("partners.titles.personalData2")} key="2">
              <Row key="relations" gutter={20}>
                <Col xs={24} lg={9}>
                  <Title level={5}>{t("partners.fields.payingMethodId")}</Title>
                  <Text>{`${t(
                    payinghMethod.find(
                      (el) => el.value === record?.payingMethodId
                    )?.label ?? "unknown"
                  )}`}</Text>

                  <Form.Item dependencies={["individual"]}>
                    {({ getFieldValue }) => {
                      return (
                        getFieldValue("individual") && (
                          <>
                            <Title level={3}></Title>
                            <Title level={5}>
                              {t("partners.fields.taxIdentificationNumber")}
                            </Title>
                            <Text>{record?.taxIdentificationNumber}</Text>

                            <Title level={5}>
                              {t("partners.fields.identityNumber")}
                            </Title>
                            <Text>{record?.identityNumber}</Text>
                          </>
                        )
                      );
                    }}
                  </Form.Item>

                  <Form.Item dependencies={["individual"]}>
                    {({ getFieldValue }) => {
                      return (
                        !getFieldValue("individual") && (
                          <>
                            <Title level={5}>
                              {t("partners.fields.taxNumber")}
                            </Title>
                            <Text>{record?.taxNumber}</Text>

                            <Title level={5}>
                              {t("partners.fields.companyNumber")}
                            </Title>
                            <Text>{record?.companyNumber}</Text>

                            <Title level={5}>
                              {t("partners.fields.euTaxNumber")}
                            </Title>
                            <Text>{record?.euTaxNumber}</Text>

                          </>
                        )
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col xs={24} lg={9}>
                  <Form.Item dependencies={["payingMethodId"]}>
                    {({ getFieldValue }) => {
                      return (
                        getFieldValue("payingMethodId") === 1 && (
                          <>
                            <Title level={5}>
                              {t("partners.fields.bankAccount")}
                            </Title>
                            <Text>{record?.bankAccount}</Text>
                          </>
                        )
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab={t("partners.titles.addressDetails")} key="3">
              <Table
                dataSource={record?.partnerAddresses}
                columns={columns}
              ></Table>
            </TabPane>
          </Tabs>
        </>
      </Form>
    </Show>
  );
};
