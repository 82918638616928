import {
  DateField,
  EditButton,
  List,
  ShowButton,
  Modal,
  Space,
  Table,
  useDrawerForm,
  useTable,
  Typography,
  Show,
  Row,
  Col,
  FileField,
  RefreshButton,
} from "@pankod/refine-antd";
import { useApiUrl, useShow, useTranslate } from "@pankod/refine-core";
import { Statuses } from "components/common";
import {
  CreateProviderContracts,
  EditProviderContracts,
} from "components/providerContract";
import { IProject } from "interfaces/project";
import { IProviderContract } from "interfaces/providercontract";
import { projectStatus } from "constants/project";
import { useState } from "react";
import dayjs from "dayjs";

export const ProjectProviderContractTable: React.FC<{ record: IProject }> = ({
  record,
}) => {
  const API_URL = useApiUrl();
  const t = useTranslate();
  const { Title, Text } = Typography;
  const [visible, setVisible] = useState(false);

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow,
  } = useDrawerForm<IProviderContract>({
    action: "create",
    resource: "provider_contracts",
    redirect: false,
    //defaultFormValues:{ name: "hhhhh"}
  });

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow2,
  } = useDrawerForm<IProviderContract>({
    action: "edit",
    resource: "provider_contracts",
    redirect: false,
  });

  const { tableProps: provider_contractsTableProps } =
    useTable<IProviderContract>({
      resource: "provider_contracts",
      permanentFilter: [
        {
          field: "project.id",
          operator: "eq",
          value: record.id,
        },
      ],
      syncWithLocation: false,
    });

  const { queryResult, showId, setShowId } = useShow<IProviderContract>({
    resource: "provider_contracts",
  });
  const { data, isLoading } = queryResult;
  const record__ = data?.data;

  return (
    <>
      <List
        title={t("provider_contracts.provider_contracts")}
        createButtonProps={{
          onClick: () => {
            createShow();
          },
          disabled:
            record?.status === projectStatus.tenders.value ||
            record?.status === projectStatus.finished.value ||
            record?.status === projectStatus.rejected.value,
        }}
      >
        <Table {...provider_contractsTableProps} rowKey="id">
          <Table.Column
            key="name"
            dataIndex="description"
            title={t("provider_contracts.fields.name")}
          />

          <Table.Column<IProviderContract>
            dataIndex="requestDate"
            title={t("provider_contracts.fields.requestDate")}
            render={(value) => <DateField format="L LT" value={dayjs(value)} />}
          />
          <Table.Column<IProviderContract>
            dataIndex="status"
            title={t("price_offers.fields.status")}
            render={(value) => {
              return <Statuses status={value} />;
            }}
          />
          <Table.Column<IProviderContract>
            title={t("table.actions")}
            dataIndex="actions"
            render={(_, rec) =>
              record?.status === projectStatus.provider_contracts.value ? (
                <Space>
                  <EditButton onClick={() => editShow2(rec.id)}>
                    {t("projects.buttons.editProviderContracts")}
                  </EditButton>
                </Space>
              ) : (
                <Space>
                  <ShowButton
                    size="small"
                    resourceNameOrRouteName="provider_contracts"
                    recordItemId={rec.id}
                    onClick={() => {
                      setShowId(rec.id);
                      setVisible(true);
                    }}
                  />
                </Space>
              )
            }
          />
        </Table>
      </List>
      <Modal visible={visible} closable={false} footer={false} width={800}>
        <Show
          isLoading={isLoading}
          pageHeaderProps={{
            onBack: () => setVisible(false),
            extra: <RefreshButton hidden/>
          }}
          resource="provider_contracts"
          recordItemId={showId}
        >
          <Row gutter={20}>
            <Col xs={24} lg={30}>
              <Row gutter={10}>
                <Col xs={24} lg={12}>
                  <Title level={5}>{t("provider_contracts.fields.name")}</Title>
                  <Text>{record__?.description}</Text>

                  <Title level={5}>
                    {t("provider_contracts.fields.status")}
                  </Title>
                  <Text>{t(`statusTypes.${record__?.status}`)}</Text>

                  <Title level={5}>{t("provider_contracts.fields.date")}</Title>
                  <DateField value={dayjs(record__?.date)} format="LL"  />
                </Col>
                <Col xs={24} lg={12}>
                  <Title level={5}>
                    {t("provider_contracts.fields.requestDate")}
                  </Title>
                  <DateField value={dayjs(record__?.requestDate)} format="LL" />

                  <Title level={5}>{t("provider_contracts.fields.file")}</Title>
                  <Row gutter={10}>
                  {record__?.images?.map((data: any) => {
                    return (
                      <Col xs={24} lg={12}>
                        <FileField
                          target="_blank"
                          title={data?.name}
                          src={data?.url}
                        />
                      </Col>
                    );
                  })}
                  {/*<FileField src={record.image[0].url} />*/}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Show>
      </Modal>
      <CreateProviderContracts
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
        initialValues={{
          project: `${API_URL}/projects/${record.id}`,
          status: "live",
        }}
      />

      <EditProviderContracts
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
      />
    </>
  );
};
