import {
  List,
  Table,
  useTable,
  Modal,
  Show,
  ShowButton,
  Typography,
  Col,
  Row,
  FileField,
  Button,
  DateField,
  RefreshButton,
} from "@pankod/refine-antd";
import { useApiUrl, useTranslate, useShow } from "@pankod/refine-core";
import { Statuses } from "components/common";
import { IProject } from "interfaces/project";
import { IPriceOffer } from "interfaces/priceoffer";
import { useState } from "react";

const { Title, Text } = Typography;

export const ProjectPriceOffersTable: React.FC<{ record: IProject }> = ({
  record: project,
}) => {
  const API_URL = useApiUrl();
  const t = useTranslate();
  const [visible, setVisible] = useState(false);

  const { tableProps: price_offersableProps } = useTable<IPriceOffer>({
    resource: "price_offers",
    permanentFilter: [
      {
        field: "project.id",
        operator: "eq",
        value: project.id,
      },
    ],
    syncWithLocation: false,
  });

  const { queryResult, showId, setShowId } = useShow<IPriceOffer>({
    resource: "price_offers",
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;
  return (
    <>
      <List title={t("price_offers.price_offers")}>
        <Table {...price_offersableProps} rowKey="id">
          <Table.Column
            key="description"
            dataIndex="description"
            title={t("price_offers.fields.description")}
          />

          <Table.Column<IPriceOffer>
            dataIndex="status"
            title={t("price_offers.fields.status")}
            render={(value) => {
              return <Statuses status={value} />;
            }}
          />
          <Table.Column<IPriceOffer>
            title={t("table.actions")}
            dataIndex="actions"
            render={(_, record) => (
              <ShowButton
                size="small"
                recordItemId={record.id}
                onClick={() => {
                  setShowId(record.id);
                  setVisible(true);
                }}
              />
            )}
          />
        </Table>
      </List>
      <Modal visible={visible} closable={false} footer={false} width={700}>
        <Show
          isLoading={isLoading}
          pageHeaderProps={{
            onBack: () => setVisible(false),
            extra: <RefreshButton hidden/>
          }}
          resource="price_offers"
          recordItemId={showId}
        >
          <Row gutter={20}>
            <Col xs={24} lg={30}>
              <Row gutter={10}>
                <Col xs={24} lg={12}>
                  <Title level={5}>
                    {t("price_offers.fields.description")}
                  </Title>
                  <Text>{record?.description}</Text>

                  <Title level={5}>{t("price_offers.fields.status")}</Title>
                  <Text>{t(`statusTypes.${record?.status}`)}</Text>

                  <Title level={5}>{t("price_offers.fields.date")}</Title>
                  <DateField value={record?.date} format="LL" />
                </Col>
                <Col xs={24} lg={12}>
                  <Title level={5}>{t("contracts.fields.dateValid")}</Title>
                  <DateField value={record?.validity} format="LL" />

                  <Title level={5}>{t("price_offers.fields.file")}</Title>
                  <Row gutter={10}>
                  {record?.images?.map((data: any) => {
                    return (
                      <Col xs={24} lg={24}>
                        <FileField
                          target="_blank"
                          title={data?.name}
                          src={data?.url}
                        />
                      </Col>
                    );
                  })}
                  {/*<FileField src={record.image[0].url} />*/}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Show>
      </Modal>
    </>
  );
};
