import { useTranslate, useApiUrl } from "@pankod/refine-core";

import {
  Edit,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  ButtonProps,
  Typography,
  Upload,
  Grid,
  getValueFromEvent,
  useSelect,
  SaveButton,
} from "@pankod/refine-antd";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { TOKEN_KEY } from "../../constants";

import {
  normalizeFile,
  mediaUploadMapper,
  getValueProps,
} from "utility/normalize";
import { useApiUpload } from "hooks/upload";
import { statusTypes } from "constants/common";

const { Text } = Typography;

type EditPriceOfferProps = {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
};

export const EditPriceOffer: React.FC<EditPriceOfferProps> = ({
  drawerProps,
  formProps,
  saveButtonProps,
}) => {
  const { ...uploadProps } = useApiUpload({
    maxCount: 2,
  });

  const t = useTranslate();
  const apiUrl = useApiUrl();
  const breakpoint = Grid.useBreakpoint();

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
    >
      <Edit
        resource="price_offers"
        pageHeaderProps={{ extra: null }}
        actionButtons={
          <>
            <SaveButton {...saveButtonProps} style={{ marginRight: 10 }} />
          </>
        }
      >
        <Form
          {...formProps}
          layout="vertical"
          onFinish={(values) => {
            values = { ...values, images: getValueProps(values?.images, "") };
            return formProps.onFinish?.(mediaUploadMapper(values));
          }}
        >
          <Form.Item
            label={t("price_offers.fields.description")}
            name="description"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("price_offers.fields.date")}
            name="date"
            rules={[
              {
                required: true,
              },
            ]}
            getValueProps={(value) => ({
              value: value ? dayjs(value) : "",
            })}
          >
            <DatePicker placeholder={t("price_offers.fields.date")} />
          </Form.Item>

          <Form.Item
            label={t("price_offers.fields.validity")}
            name="validity"
            rules={[
              {
                required: true,
              },
            ]}
            getValueProps={(value) => ({
              value: value ? dayjs(value) : "",
            })}
          >
            <DatePicker placeholder={t("price_offers.fields.validity")} />
          </Form.Item>
          <Form.Item
            label={t("price_offers.fields.file")}
            name="images"
            //getValueProps={(record) => {return record?.fileList}}
            //valuePropName="fileList"

            normalize={normalizeFile}
            //getValueFromEvent={getValueFromEvent}
            getValueProps={(data) => getValueProps(data, "")}
            valuePropName="fileList"
            noStyle
          >
            <Upload.Dragger
              name="file"
              action={`${apiUrl}/media_objects`}
              headers={{
                Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
              }}
              listType="picture"
              multiple
              {...uploadProps}
            >
              <p className="ant-upload-text">{t("upload.areaTitle")}</p>
            </Upload.Dragger>
          </Form.Item>
          <Form.Item label={t("price_offers.fields.status")} name="status">
            <Select
              options={Object.values(statusTypes).map((p) => ({
                label: t(`${p.label}`),
                value: p.value,
              }))}
              placeholder={t("buttons.pleaseSelect")}
            />
          </Form.Item>
        </Form>
      </Edit>
    </Drawer>
  );
};
