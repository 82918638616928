import {
  useTranslate,
  HttpError,
  CrudFilters,
  CrudFilter,
  useList,
  useShow,
  useGetIdentity,
} from "@pankod/refine-core";

import {
  List,
  Space,
  EditButton,
  ShowButton,
  TextField,
  TagField,
  DateField,
  Table,
  useTable,
  useDrawerForm,
  Drawer,
  Create,
  Form,
  Input,
  Radio,
  useSelect,
  getDefaultSortOrder,
  useModal,
  Modal,
  Button,
  useStepsForm,
  InputNumber,
  useModalForm,
  Icons,
  CreateButton,
} from "@pankod/refine-antd";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { publicAreaTypes, addressTypes } from "constants/address";

import {
  Badge,
  Card,
  Cascader,
  Checkbox,
  Col,
  FormProps,
  Row,
  Select,
  Steps,
  Typography,
} from "antd";
import { IProject, IProjectFilter } from "interfaces/project";
import { IPartner } from "interfaces/partner";
import { ILocation } from "interfaces/location";
import { omit } from "lodash";
import { PartnerCreate } from "pages/partners/create";
import React, { useEffect, useState } from "react";
import { PartnerSelect } from "components/partner/PartnersSelect";
import { ICompany } from "interfaces/company";
import { IUser } from "interfaces/user";
import { flatten } from "dataProvider";
const { Step } = Steps;

export const ProjectList: React.FC = () => {
  const {
    selectProps: partnerSelectProps,
    queryResult: partnerQueryResult,
    defaultValueQueryResult: partnerDefaultValueQueryResult,
  } = useSelect<IPartner>({
    resource: "partners",
    onSearch: (value) => [
      {
        field: "name",
        operator: "eq",
        value,
      },
    ],
  });

  const { Title, Text } = Typography;

  const partnerAllOptions = [
    ...(partnerQueryResult.data?.data || []),
    ...(partnerDefaultValueQueryResult.data?.data || []),
  ];
  const {
    modalProps: useModalProps,
    show: useModalShow,
    close: closePartnerModal,
  } = useModal();

  const {
    modalProps: partnerSelectModal,
    show: partnerSelectShow,
    close: partnerSelectClose,
  } = useModal();

  const {
    selectProps: locationSelectProps,
    queryResult: locationQueryResult,
    defaultValueQueryResult: locationDefaultValueQueryResult,
  } = useSelect<ILocation>({
    resource: "locations",
  });

  const allOptions = [
    ...(locationQueryResult.data?.data || []),
    ...(locationDefaultValueQueryResult.data?.data || []),
  ];

  const t = useTranslate();
  const { formProps, drawerProps, show, saveButtonProps } =
    useDrawerForm<IProject>({
      action: "create",
    });

  const {
    tableProps: projectTable,
    sorter,
    filters,
    searchFormProps,
  } = useTable<IProject, HttpError, IProjectFilter>({
    permanentSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, podNumber, partnerName, tenders } = params;

      filters.push({
        field: "name",
        operator: "eq",
        value: q || undefined,
      });

      filters.push({
        field: "technicalDatasheets.podNumber",
        operator: "eq",
        value: podNumber || undefined,
      });

      filters.push({
        field: "exists[tenders]",
        operator: "eq",
        value: tenders ?? undefined,
      });

      filters.push({
        field: "partner.name",
        operator: "eq",
        value: partnerName,
      });

      return filters;
    },
    //syncWithLocation: false,
  });

  const { data: projectData } = useList<IProject>({
    resource: "projects",
    config: {
      filters: [
        {
          field: "paginate",
          operator: "eq",
          value: false,
        },
      ],
    },
  });

  const { data: user } = useGetIdentity();
  const { setShowId: setUserShowId, queryResult: companyQuery } =
    useShow<IUser>({
      resource: "users",
      id: user?.id,
    });
  const { data: companyData } = companyQuery;
  const companyRecord = companyData?.data;

  useEffect(() => {
    setUserShowId(user?.id);
  }, [user]);

  const onPartnerSelectChange = (selectedRowKeys: React.Key) => {
    // alert(JSON.stringify(selectedRowKeys));
    //setSelectedRowKey(selectedRowKeys);

    formProps.form.setFieldsValue({
      partner: { id: selectedRowKeys },
    });
    // formProps.form.validateFields();
    // alert(JSON.stringify(selectedRowKeys));
  };

  return (
    <>
      {/*<Text>{JSON.stringify(user)}</Text>*/}
      <Row gutter={[16, 16]}>
        <Col xl={6} lg={24} xs={24}>
          <Card title={t("table.filterTitle")}>
            <Filter formProps={searchFormProps} />
          </Card>
        </Col>
        <Col xl={18} xs={24}>
          {/*<Text>{companyRecord?.maxProjectNumber}</Text>*/}
          <List
            pageHeaderProps={{
              extra: (
                <CreateButton
                  disabled={
                    companyRecord?.maxProjectNumber &&
                    projectData?.data?.length &&
                    companyRecord.maxProjectNumber - projectData.data.length <=
                      0
                      ? true
                      : false
                  }
                  resourceNameOrRouteName={"projects"}
                  onClick={() => {
                    show();
                  }}
                >
                  {t("tenders.titles.createProject")}
                </CreateButton>
              ),
            }}
          >
            <Table {...projectTable} rowKey="id">
              <Table.Column
                dataIndex="name"
                title={t("projects.fields.name")}
              />

              <Table.Column
                dataIndex="status"
                title={t("projects.fields.status")}
                render={(value: any) => {
                  return t(`projects.statuses.${value}`);
                }}
              />
              <Table.Column
                dataIndex="technicalDatasheets"
                title={t("projects.fields.technicalDatasheets")}
                render={(value: any) => {
                  return Array.isArray(value) ? (
                    <Badge
                      style={{ backgroundColor: "#52c41a" }}
                      count={value?.length}
                    ></Badge>
                  ) : (
                    //     value.map((v) => <TagField value={v} />)
                    <TagField value={value} />
                  );
                }}
              />

              <Table.Column
                dataIndex="priceOffer"
                title={t("projects.fields.priceOffer")}
                render={(value: any) => {
                  return Array.isArray(value) ? (
                    <Badge
                      style={{ backgroundColor: "#52c41a" }}
                      count={value?.length}
                    ></Badge>
                  ) : (
                    <TagField value={value} />
                  );
                }}
              />

              <Table.Column
                dataIndex="contracts"
                title={t("projects.fields.contracts")}
                render={(value: any) => {
                  return Array.isArray(value) ? (
                    <Badge
                      style={{ backgroundColor: "#52c41a" }}
                      count={value?.length}
                    ></Badge>
                  ) : (
                    <TagField value={value} />
                  );
                }}
              />

              <Table.Column
                dataIndex="providerContract"
                title={t("projects.fields.providerContract")}
                render={(value: any) => {
                  return Array.isArray(value) ? (
                    <Badge
                      style={{ backgroundColor: "#52c41a" }}
                      count={value?.length}
                    ></Badge>
                  ) : (
                    <TagField value={value} />
                  );
                }}
              />

              <Table.Column
                dataIndex="tenders"
                title={t("projects.fields.tenders")}
                render={(value: any) => {
                  return Array.isArray(value) ? (
                    <Badge
                      style={{ backgroundColor: "#52c41a" }}
                      count={value?.length}
                    ></Badge>
                  ) : (
                    <TagField value={value} />
                  );
                }}
              />

              <Table.Column
                dataIndex="partner"
                title={t("projects.fields.partnerName")}
                render={(value: any) => {
                  return value && <TagField value={`${value?.name}`} />;
                }}
              />
              <Table.Column<IProject>
                title={t("table.actions")}
                dataIndex="actions"
                render={(_, record) => (
                  <Space>
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                    <ShowButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  </Space>
                )}
              />
            </Table>
          </List>
        </Col>
      </Row>
      <Drawer {...drawerProps}>
        <Create saveButtonProps={saveButtonProps}>
          <Form {...formProps} layout="vertical"
          onFinish={(values) => {
            values = {
              ...values,
              partner: flatten(formProps.form?.getFieldsValue(true)?.partner),
              agent: flatten(formProps.form?.getFieldsValue(true)?.agent),
            };
            //alert(JSON.stringify(flatten(formProps.form?.getFieldsValue(true)?.partner)))
            return formProps.onFinish?.(values);
          }}
          >
            <Form.Item
              label={t("projects.fields.name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("defaultValidateMessages.required", {
                    name: "${label}",
                  }),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("projects.fields.partner")}
              name={["partner"]}
              rules={[
                {
                  required: true,
                  message: t("defaultValidateMessages.required", {
                    name: "${label}",
                  }),
                },
              ]}
            >
              <PartnerSelect />
            </Form.Item>
            <Space>
              <Button onClick={useModalShow}>
                {t("buttons.modalPartnerCreate")}
              </Button>
            </Space>
          </Form>
        </Create>
        <Modal width={1000} {...useModalProps} footer={false}>
          <PartnerCreate
            options={{ redirect: false, close: closePartnerModal }}
          />
        </Modal>
      </Drawer>
    </>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = (props) => {
  const t = useTranslate();
  return (
    <Form layout="vertical" {...props.formProps}>
      <Row gutter={[10, 10]} align="bottom">
        <Col xs={24} xl={24} md={12}>
          <Form.Item label={t("projects.fields.name")} name="q">
            <Input
              allowClear
              placeholder={t("projects.fields.name")}
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={12}>
          <Form.Item
            label={t("projects.fields.partnerName")}
            name="partnerName"
          >
            <Input
              allowClear
              placeholder={t("projects.fields.partnerName")}
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={12}>
          <Form.Item
            label={t("technical_datasheets.fields.podNumber")}
            name="podNumber"
          >
            <Input
              allowClear
              placeholder={t("technical_datasheets.fields.podNumber")}
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={12}>
          <Form.Item label={t("projects.fields.tenders")} name="tenders">
            <Select
              allowClear
              placeholder={t("projects.fields.tenders")}
              options={[
                {
                  label: t("tenders.filters.exists"),
                  value: true,
                },
                {
                  label: t("tenders.filters.notExists"),
                  value: false,
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[10, 40]} align="bottom">
        <Col xs={24} xl={24} md={8}>
          <Form.Item>
            <Button style={{ width: "100%" }} htmlType="submit" type="primary">
              {t("buttons.filter")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
