import { useEffect, useState } from "react";
import {
  CrudFilters,
  HttpError,
  useShow,
  useTranslate,
} from "@pankod/refine-core";
import { IPartner, IPartnerFilter } from "interfaces/partner";
import {
  BooleanField,
  Button,
  ButtonProps,
  Card,
  Col,
  CreateButton,
  Form,
  FormProps,
  getDefaultSortOrder,
  Grid,
  Icons,
  Input,
  List,
  Modal,
  Row,
  Select,
  Space,
  Table,
  TextField,
  Tooltip,
  Typography,
  useModal,
  useSelect,
  useTable,
} from "@pankod/refine-antd";
import { IAddress } from "interfaces/address";

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

type PartnerSelectProps = {
  value?: IPartner;
  onChange?: (partner?: IPartner) => void;
  disabled?: boolean;
};

/**
 * @param value initialValue passed by parent Form.Item.
 * @param onChange a callback for Form.Item to read its child component's value.
 * @param disabled
 * @constructor
 */
export const PartnerSelect: React.FC<PartnerSelectProps> = ({
  value,
  onChange,
  disabled,
}) => {
  const t = useTranslate();
  const [partner, setPartner] = useState<IPartner | undefined>(value);
  const { tableProps, sorter, filters, searchFormProps } = useTable<
    IPartner,
    HttpError,
    IPartnerFilter
  >({
    resource: "partners",
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],

    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, individual } = params;

      filters.push({
        field: "name",
        operator: "eq",
        value: q,
      });

      filters.push({
        field: "individual",
        operator: "eq",
        value: individual,
      });

      return filters;
    },
    syncWithLocation: false,
  });
  const { xl } = useBreakpoint();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([
    value?.id ?? "",
  ]);

  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const {
    modalProps: partnerSelectModal,
    show: partnerSelectShow,
    close: partnerSelectClose,
  } = useModal();

  const { setShowId, queryResult: partnerQueryResult } = useShow<IPartner>({
    resource: "partners",

    id: partner?.id,
  });

  let promise = new Promise(function (resolve, reject) {
    if (typeof onChange === "function") {
      resolve(onChange(partner));
    } else {
      resolve(true);
    }
  });
  useEffect(() => {
    if (value && partner?.id !== value?.id) {
      setPartner(value);
    }
  }, [value]);

  useEffect(() => {
    if (partnerQueryResult.isFetched) {
      setPartner(partnerQueryResult.data?.data);
    }
  }, [partnerQueryResult]);

  useEffect(() => {
    if (partnerQueryResult.isFetched && !partnerQueryResult.isError) {
      promise.then(partnerSelectClose);
    }
  }, [partner]);

  const renderAddress = (address: IAddress | undefined) => {
    const tp = t(`addresses.enum.publicAreaTypes.${address?.publicAreaType}`);
    return (
      <>
        <Text>{`${address?.locationPostCode},${address?.locationName}`}</Text>
        <Text>{`${address?.publicAreaName} ${t(
          `addresses.enum.publicAreaTypes.${address?.publicAreaType}`
        )} ${address?.houseNumber}`}</Text>
      </>
    );
  };

  const change = async (selectedRowKeys: React.Key[]) => {
    if (partner?.id !== selectedRowKeys[0]) setShowId(selectedRowKeys[0]);
    else partnerSelectClose();
  };

  return (
    <>
      <Space>
        <Tooltip
          title={
            <Card
              title={
                <Space
                  direction="vertical"
                  style={{
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                      textAlign: xl ? "unset" : "center",
                    }}
                  >
                    {renderAddress(partner?.partnerAddresses?.[0].address)}
                  </Space>

                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                      textAlign: xl ? "unset" : "center",
                    }}
                  ></Space>
                </Space>
              }
              bordered={false}
              style={{ height: "100%" }}
            >
              <Space
                direction="vertical"
                style={{ width: "100%", height: "100%" }}
              >
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                    textAlign: xl ? "unset" : "center",
                  }}
                >
                  <Typography.Text>
                    <Icons.MailOutlined /> {partner?.email}
                  </Typography.Text>
                  <Typography.Text>
                    <Icons.PhoneOutlined /> {partner?.phoneNumber}
                  </Typography.Text>
                  {partner?.individual ? (
                    <Typography.Text>
                      <Icons.CheckOutlined /> {t("partners.fields.individual")}
                    </Typography.Text>
                  ) : (
                    <Typography.Text>
                      <Icons.CloseOutlined /> {t("partners.fields.individual")}
                    </Typography.Text>
                  )}
                  <Typography.Text>
                    <Icons.CalendarOutlined /> {partner?.createdAt}
                  </Typography.Text>
                </Space>
              </Space>
            </Card>
          }
        >
          <TextField value={partner?.name} />
        </Tooltip>
        <Space>
          {" "}
          {!disabled && (
            <Button
              icon={<Icons.UserAddOutlined />}
              onClick={() => {
                partnerSelectShow();
              }}
            ></Button>
          )}
        </Space>
      </Space>

      <Modal
        width={1200}
        {...partnerSelectModal}
        onCancel={partnerSelectClose}
        onOk={() => {
          change(selectedRowKeys);
        }}
      >
        <List
          title={t("partners.partners")}
          resource="partners"
          pageHeaderProps={{
            extra: <CreateButton hidden={true} />,
          }}
        >
          <Form
            {...searchFormProps}
            layout="vertical"
            onValuesChange={() => searchFormProps.form?.submit()}
          >
            <Space wrap>
              <Form.Item
                label={t("partners.titles.filterSearchLabel")}
                name="q"
              >
                <Input
                  placeholder={t("partners.fields.namePlaceholder")}
                  prefix={<Icons.SearchOutlined />}
                />
              </Form.Item>
              <Form.Item
                label={t("partners.titles.filterIndividualLabel")}
                name="individual"
              >
                <Select
                  allowClear
                  placeholder={t("partners.fields.individualPlaceholder")}
                  options={[
                    {
                      label: t("partners.fields.individualTrue"),
                      value: true,
                    },
                    {
                      label: t("partners.fields.individualFalse"),
                      value: false,
                    },
                  ]}
                />
              </Form.Item>
            </Space>
          </Form>

          <Table
            {...tableProps}
            rowKey="id"
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
          >
            <Table.Column
              dataIndex="id"
              key="id"
              title="ID"
              render={(value) => <TextField value={value} />}
            />

            <Table.Column
              dataIndex="name"
              key="name"
              title={t("partners.fields.name")}
              render={(value) => <TextField value={value} />}
              sorter
              filterSearch={true}
            />

            <Table.Column
              dataIndex="individual"
              key="individual"
              title={t("partners.fields.individual")}
              render={(value) => (
                <BooleanField
                  value={value}
                  valueLabelFalse={t("partners.fields.individualFalse")}
                  valueLabelTrue={t("partners.fields.individualTrue")}
                />
              )}
              sorter
            />

            <Table.Column
              dataIndex="identityNumber"
              key="identityNumber"
              title={t("partners.fields.identityNumber")}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("identityNumber", sorter)}
              sorter
            />

            <Table.Column
              dataIndex="companyNumber"
              key="companyNumber"
              title={t("partners.fields.companyNumber")}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("companyNumber", sorter)}
              sorter
            />
          </Table>
        </List>
      </Modal>
    </>
  );
};
const Filter: React.FC<{ formProps: FormProps }> = (props) => {
  const t = useTranslate();
  return (
    <Form layout="vertical" {...props.formProps}>
      <Row gutter={[10, 40]} align="bottom">
        <Col xs={24} xl={24} md={12}>
          <Form.Item label={t("partners.titles.filterSearchLabel")} name="q">
            <Input
              placeholder={t("partners.fields.namePlaceholder")}
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>

        <Col xs={24} xl={24} md={8}>
          <Form.Item
            label={t("partners.titles.filterIndividualLabel")}
            name="individual"
          >
            <Select
              allowClear
              placeholder={t("partners.fields.individualPlaceholder")}
              options={[
                {
                  label: t("partners.fields.individualTrue"),
                  value: true,
                },
                {
                  label: t("partners.fields.individualFalse"),
                  value: false,
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col xs={24} xl={24} md={8}>
          <Form.Item>
            <Button style={{ width: "100%" }} htmlType="submit" type="primary">
              {t("buttons.filter")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
