import { usePermissions } from "@pankod/refine-core";
import { findAllInRenderedTree } from "react-dom/test-utils";
import { TOKEN_KEY, REFRESH_KEY, ROLES_KEY } from "./constants";

type CanParams = {
  resource: string;
  action: string;
  params?: any;
};

type CanReturnType = {
  can: boolean;
  reason?: string;
};

export const ApiAccessControlProvider = () => {
  //  const { data: roles } = usePermissions();
  const accessControlProvider = {
    can: async ({ resource, action, params }: CanParams) => {
      const token = localStorage.getItem(ROLES_KEY);

      //let roles =  ["ROLE_USER"];

      const roles = token ? JSON.parse(token ?? "") : ["ROLE_GUEST"];

      if (resource === "technical_datasheets" && action === "list") {
        return Promise.resolve({
          can: false,
          reason: "Unauthorized",
        });
      }

      if (resource === "price_offers" && action === "list") {
        return Promise.resolve({
          can: false,
          reason: "Unauthorized",
        });
      }

      if (resource === "contracts" && action === "list") {
        return Promise.resolve({
          can: false,
          reason: "Unauthorized",
        });
      }

      if (resource === "provider_contracts" && action === "list") {
        return Promise.resolve({
          can: false,
          reason: "Unauthorized",
        });
      }

      if (resource === "tenders" && action === "list") {
        return Promise.resolve({
          can: false,
          reason: "Unauthorized",
        });
      }

      if (roles?.includes("ROLE_SUPER_ADMIN"))
        return Promise.resolve({ can: true });

      if (
        !(roles?.includes("ROLE_AGENT") || roles?.includes("ROLE_USER")) &&
        resource === "partners" &&
        (action === "list" ||
          action === "create" ||
          action === "delete" ||
          action === "edit" ||
          action === "show")
      ) {
        return Promise.resolve({
          can: false,
          reason: "Unauthorized",
        });
      }

      if (
        !(roles?.includes("ROLE_AGENT") || roles?.includes("ROLE_USER")) &&
        resource === "locations" &&
        (action === "list" ||
          action === "create" ||
          action === "delete" ||
          action === "edit" ||
          action === "show")
      ) {
        return Promise.resolve({
          can: false,
          reason: "Unauthorized",
        });
      }

      if (
        !roles?.includes("ROLE_SUPER_ADMIN") &&
        resource === "companies" &&
        (action === "list" ||
          action === "create" ||
          action === "delete" ||
          action === "edit" ||
          action === "show")
      ) {
        return Promise.resolve({
          can: false,
          reason: "Unauthorized",
        });
      }

      /*
      if (
        !roles?.includes("ROLE_TENDER_WRITER") &&
        resource === "projects" &&
        ( action === "edit")
      ) {
        return Promise.resolve({
          can: false,
          reason: "Unauthorized",
        });
      }
     */

      if (
        !(roles?.includes("ROLE_AGENT") || roles?.includes("ROLE_USER")) &&
        resource === "projects" &&
        (action === "list" ||
          action === "create" ||
          action === "delete" ||
          action === "edit" ||
          action === "show")
      ) {
        return Promise.resolve({
          can: false,
          reason: "Unauthorized",
        });
      }
      return Promise.resolve({ can: true });
    },
  };
  return {
    ...accessControlProvider,
  };
};
