export const payinghMethod = [
    {
      value: 0,
      label: "partners.payingMethods.cash"
    },
    {
      value: 1,
      label: "partners.payingMethods.transfer"
    },
    {
      value: 2,
      label: "partners.payingMethods.bankCard"
    }
  ];
