import {
  Col,
  DateField,
  EditButton,
  FileField,
  List,
  Modal,
  RefreshButton,
  Row,
  Show,
  ShowButton,
  Space,
  Table,
  Typography,
  useDrawerForm,
  useTable,
} from "@pankod/refine-antd";
import { useApiUrl, useShow, useTranslate } from "@pankod/refine-core";
import { Statuses } from "components/common";
import { CreatePriceOffer, EditPriceOffer } from "components/priceOffers";
import { IPriceOffer } from "interfaces/priceoffer";
import { IProject } from "interfaces/project";
import { projectStatus } from "constants/project";
import { useState } from "react";

export const ProjectPriceOffersTable: React.FC<{ record: IProject }> = ({
  record,
}) => {
  const API_URL = useApiUrl();
  const t = useTranslate();
  const {
    drawerProps: createPODrawerProps,
    formProps: createPOFormProps,
    saveButtonProps: createPOSaveButtonProps,
    show: createPOShow,
  } = useDrawerForm<IPriceOffer>({
    action: "create",
    resource: "price_offers",
    redirect: false,
    //defaultFormValues:{ name: "hhhhh"}
  });

  const { Title, Text } = Typography;
  const [visible, setVisible] = useState(false);

  const {
    drawerProps: editPODrawerProps,
    formProps: editPOFormProps,
    saveButtonProps: editPOSaveButtonProps,
    show: editPOShow,
  } = useDrawerForm<IPriceOffer>({
    action: "edit",
    resource: "price_offers",
    redirect: false,
  });

  const { tableProps: priceOfferTableProps } = useTable<IPriceOffer>({
    resource: "price_offers",
    permanentFilter: [
      {
        field: "project.id",
        operator: "eq",
        value: record.id,
      },
    ],
    syncWithLocation: false,
  });

  const { queryResult, showId, setShowId } = useShow<IPriceOffer>({
    resource: "price_offers",
  });
  const { data, isLoading } = queryResult;
  const record__ = data?.data;

  return (
    <>
      <List
        title={t("price_offers.price_offers")}
        createButtonProps={{
          onClick: () => {
            createPOShow();
          },
          disabled:
            record?.status === projectStatus.contracts.value ||
            record?.status === projectStatus.provider_contracts.value ||
            record?.status === projectStatus.tenders.value ||
            record?.status === projectStatus.finished.value ||
            record?.status === projectStatus.rejected.value,
        }}
      >
        <Table {...priceOfferTableProps} rowKey="id">
          <Table.Column
            key="description"
            dataIndex="description"
            title={t("price_offers.fields.name")}
          />
          <Table.Column
            key="validity"
            dataIndex="validity"
            title={t("price_offers.fields.validity")}
            render={(value) => <DateField value={value} format="LLL" />}
          />

          <Table.Column<IPriceOffer>
            dataIndex="status"
            title={t("price_offers.fields.status")}
            render={(value) => {
              return <Statuses status={value} />;
            }}
          />
          <Table.Column<IPriceOffer>
            title={t("table.actions")}
            dataIndex="actions"
            render={(_, rec) =>
              record?.status === projectStatus.price_offers.value ? (
                <Space>
                  <EditButton onClick={() => editPOShow(rec.id)}>
                    {t("projects.buttons.editpriceOffers")}
                  </EditButton>
                </Space>
              ) : (
                <Space>
                  <ShowButton
                    size="small"
                    resourceNameOrRouteName="price_offers"
                    recordItemId={rec.id}
                    onClick={() => {
                      setShowId(rec.id);
                      setVisible(true);
                    }}
                  />
                </Space>
              )
            }
          />
        </Table>
      </List>
      <Modal visible={visible} closable={false} footer={false} width={700}>
        <Show
          isLoading={isLoading}
          pageHeaderProps={{
            onBack: () => setVisible(false),
            extra: <RefreshButton hidden/>
          }}
          resource="price_offers"
          recordItemId={showId}
        >
          <Row gutter={20}>
            <Col xs={24} lg={30}>
              <Row gutter={10}>
                <Col xs={24} lg={12}>
                  <Title level={5}>
                    {t("price_offers.fields.description")}
                  </Title>
                  <Text>{record__?.description}</Text>

                  <Title level={5}>{t("price_offers.fields.status")}</Title>
                  <Text>{t(`statusTypes.${record__?.status}`)}</Text>

                  <Title level={5}>{t("price_offers.fields.date")}</Title>
                  <DateField value={record__?.date} format="LL" />
                </Col>
                <Col xs={24} lg={12}>
                  <Title level={5}>{t("contracts.fields.dateValid")}</Title>
                  <DateField value={record__?.validity} format="LL" />

                  <Title level={5}>{t("price_offers.fields.file")}</Title>
                  <Row gutter={10}>
                    {record__?.images?.map((data: any) => {
                      return (
                        <Col xs={24} lg={24}>
                          <FileField
                            target="_blank"
                            title={data?.name}
                            src={data?.url}
                          />
                        </Col>
                      );
                    })}
                    {/*<FileField src={record.image[0].url} />*/}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Show>
      </Modal>
      <CreatePriceOffer
        drawerProps={createPODrawerProps}
        formProps={createPOFormProps}
        saveButtonProps={createPOSaveButtonProps}
        initialValues={{
          project: `${API_URL}/projects/${record.id}`,
          status: "live",
        }}
      />

      <EditPriceOffer
        drawerProps={editPODrawerProps}
        formProps={editPOFormProps}
        saveButtonProps={editPOSaveButtonProps}
      />
    </>
  );
};
