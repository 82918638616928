import { useTranslate } from "@pankod/refine-core";

import { Tag } from "@pankod/refine-antd";

type TechnicalDatasheetProps = {
    status: "inProgress" | "actual" | "invalid";
};

export const TechnicalDatasheetStatus: React.FC<TechnicalDatasheetProps> = ({ status }) => {
    const t = useTranslate();
    let color;

    switch (status) {
        case "inProgress":
            color = "orange";
            break;
        case "actual":
            color = "cyan";
            break;
        case "invalid":
            color = "green";
            break;
    }

    return <Tag color={color}>{t(`technical_datasheets.statusTypes.${status}`)}</Tag>;
};