import { useTranslate, useNavigation } from "@pankod/refine-core";
import {
    Typography,
    Timeline,
    useSimpleList,
    AntdList,
    Tooltip,
} from "@pankod/refine-antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { IProject } from "interfaces/project";
import "./style.less";
import { projectStatusColor } from "constants/project";

dayjs.extend(relativeTime);

export const ProjectTimeline: React.FC = () => {
    const t = useTranslate();
    const { show } = useNavigation();

    const { listProps } = useSimpleList<IProject>({
        resource: "projects",
        //****Reverse */
        permanentSorter: [    
            {
                field: "createdAt",
                order: "desc",
            },
            {
                field: "updatedAt",
                order: "desc",
            },                           
           
      
        ],
        pagination: {
            pageSize: 6,
            simple: true,
        },
    });

    const { dataSource } = listProps;

    const { Text } = Typography;

   

    return (
        <AntdList {...listProps}>
            <Timeline>
                {dataSource?.map(({ updatedAt ,createdAt, name, status, id }) => (
                    <Timeline.Item
                        className="timeline__point"
                        key={id}
                        color={projectStatusColor(status.toString())?.color}
                    >
                        <div
                            className={`timeline ${
                                projectStatusColor(status.toString())?.color
                            }`}
                        >
                            <Tooltip
                                overlayInnerStyle={{ color: "#626262" }}
                                color="rgba(255, 255, 255, 0.3)"
                                placement="topLeft"
                                title={dayjs(updatedAt).format("lll")}
                            >
                                <Text italic className="createdAt">
                                    {dayjs(updatedAt).fromNow()}
                                </Text>
                            </Tooltip>
                            <Text>
                                {t(
                                    `projects.statuses.${status}`,
                                )}
                            </Text>
                            <Text
                                onClick={() => show("projects", id)}
                                strong
                                className="number"
                            >
                                #{name}
                            </Text>
                        </div>
                    </Timeline.Item>
                ))}
            </Timeline>
        </AntdList>
    );
};
