import { Show, Typography, Tag, Col, Row } from "@pankod/refine-antd";
import { useOne, useShow, useTranslate } from "@pankod/refine-core";
import { ITechnicalDatasheet } from "interfaces/technicaldatasheet";

const { Title, Text } = Typography;

export const TechnicalDatasheetShow = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const t = useTranslate();
  
    return (
        <Show isLoading={isLoading}>
             <Row gutter={20}>
                <Col xs={24} lg={16}>
                <Row gutter={10}>
                    <Col xs={24} lg={12}>

                        <Title level={5}>{t("technical_datasheets.fields.name")}</Title>
                        <Text>{record?.name}</Text>

                        <Title level={5}>{t("technical_datasheets.fields.addressId")}</Title>
                        <Text>{record?.address}</Text>

                        <Title level={5}>{t("technical_datasheets.fields.providerPartnerNumber")}</Title>
                        <Text>{record?.providerPartnerNumber}</Text>

                        <Title level={5}>{t("technical_datasheets.fields.providerContractNumber")}</Title>
                        <Text>{record?.providerContractNumber}</Text>

                        <Title level={5}>{t("technical_datasheets.fields.status")}</Title>
                        <Text>{t(`technical_datasheets.statusTypes.${record?.status}`)}</Text>

                        <Title level={5}>{t("technical_datasheets.fields.providerType")}</Title>
                        <Text>
                        <Tag>{record?.providerType}</Tag>
                        </Text>

                        <Title level={5}>{t("technical_datasheets.fields.podNumber")}</Title>
                        <Text>{record?.podNumber}</Text>

                        <Title level={5}>{t("technical_datasheets.fields.providerMeasuringClock")}</Title>
                        <Text>{record?.providerMeasuringClock}</Text>

                        </Col>
           
                        <Col xs={24} lg={12}>

                        <Title level={5}>{t("technical_datasheets.fields.phaseR")}</Title>
                        <Text>{record?.phaseR}</Text>

                        <Title level={5}>{t("technical_datasheets.fields.phaseS")}</Title>
                        <Text>{record?.phaseS}</Text>

                        <Title level={5}>{t("technical_datasheets.fields.phaseT")}</Title>
                        <Text>{record?.phaseT}</Text>

                        <Title level={5}>{t("technical_datasheets.fields.plannedConsumptionkWh")}</Title>
                        <Text>{record?.plannedConsumptionkWh}</Text>

                        <Title level={5}>{t("technical_datasheets.fields.yearlyConsumptionFt")}</Title>
                        <Text>{record?.yearlyConsumptionFt}</Text>

                        <Title level={5}>{t("technical_datasheets.fields.yearlyConsumptionkWh")}</Title>
                        <Text>{record?.yearlyConsumptionkWh}</Text>

                        <Title level={5}>{t("technical_datasheets.fields.plannedConsumptionFt")}</Title>
                        <Text>{record?.plannedConsumptionFt}</Text>

                        </Col>
                    </Row>
                </Col>
            </Row>

        </Show>
    );
};