import { useShow, useTranslate } from "@pankod/refine-core";

import {
  List,
  TextField,
  TagField,
  DateField,
  Table,
  useTable,
  useDrawerForm,
  Drawer,
  Create,
  Form,
  Input,
  EditButton,
  Edit,
  Show,
  DeleteButton,
  Title,
  ShowButton,
  Space,
  Typography,
} from "@pankod/refine-antd";

import { ICompany } from "interfaces/company";
import { useState } from "react";

export const CompaniesList: React.FC = () => {
  const { tableProps } = useTable<ICompany>();
  const t = useTranslate();

  const { formProps, drawerProps, show, saveButtonProps } =
    useDrawerForm<ICompany>({
      action: "create",
    });

  const {
    drawerProps: editdrawerProps,
    formProps: editformProps,
    show: editShow,
    saveButtonProps: editsaveButtonProps,
    deleteButtonProps,
    id,
  } = useDrawerForm<ICompany>({
    action: "edit",
  });

  const { queryResult, showId, setShowId } = useShow<ICompany>();
  const { data, isLoading } = queryResult;

  const [visibleShowDrawer, setVisibleShowDrawer] = useState<boolean>(false);

  const { data: showQueryResult, isLoading: showIsLoading } = queryResult;
  const record = showQueryResult?.data;
  const { Title, Text } = Typography;

  return (
    <>
      <List
        createButtonProps={{
          children: t("companies.titles.create"),
          onClick: () => {
            show();
          },
        }}
      >
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex="id" title="ID" />

          <Table.Column dataIndex="name" title={t("companies.fields.name")} />

          <Table.Column
            dataIndex="companyNumber"
            title={t("companies.fields.companyNumber")}
          />
          <Table.Column<ICompany>
            title={t("table.actions")}
            dataIndex="actions"
            key="actions"
            render={(_value, record) => (
              <Space>
                <EditButton
                  size="small"
                  recordItemId={record.id}
                  onClick={() => editShow(record.id)}
                />
                <DeleteButton 
                   size="small"
                   recordItemId={record.id} 
                />
                <ShowButton
                  size="small"
                  hideText
                  recordItemId={record.id}
                  onClick={() => {
                    setShowId(record.id);
                    setVisibleShowDrawer(true);
                  }}
                />
              </Space>
            )}
          />
        </Table>
      </List>

      <Drawer {...drawerProps}>
        <Create saveButtonProps={saveButtonProps}>
          <Form {...formProps} layout="vertical">
            <Form.Item label="id" name="id" hidden>
              <Input hidden />
            </Form.Item>

            <Form.Item label={t("companies.fields.name")} name="name">
              <Input />
            </Form.Item>

            <Form.Item label={t("companies.fields.address")} name="address">
              <Input />
            </Form.Item>

            <Form.Item label={t("companies.fields.taxNumber")} name="taxNumber">
              <Input />
            </Form.Item>

            <Form.Item
              label={t("companies.fields.euTaxNumber")}
              name="taxNumberEu"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("companies.fields.companyNumber")}
              name="companyNumber"
            >
              <Input />
            </Form.Item>
          </Form>
        </Create>
      </Drawer>

      <Drawer {...editdrawerProps}>
        <Edit
          saveButtonProps={editsaveButtonProps}
          deleteButtonProps={deleteButtonProps}
          recordItemId={id}
        >
          <Form {...editformProps} layout="vertical">
            <Form.Item label="id" name="id" hidden>
              <Input hidden />
            </Form.Item>

            <Form.Item label={t("companies.fields.name")} name="name">
              <Input />
            </Form.Item>

            <Form.Item label={t("companies.fields.address")} name="address">
              <Input />
            </Form.Item>

            <Form.Item label={t("companies.fields.taxNumber")} name="taxNumber">
              <Input />
            </Form.Item>

            <Form.Item
              label={t("companies.fields.euTaxNumber")}
              name="taxNumberEu"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("companies.fields.companyNumber")}
              name="companyNumber"
            >
              <Input />
            </Form.Item>
          </Form>
        </Edit>
      </Drawer>

      <Drawer
        visible={visibleShowDrawer}
        onClose={() => setVisibleShowDrawer(false)}
        width="500"
      >
        <Show
          isLoading={showIsLoading}
          actionButtons={<DeleteButton recordItemId={showId} />}
        >
          <Title level={5}>ID</Title>
          <Text>{record?.id}</Text>

          <Title level={5}>{t("companies.fields.name")}</Title>
          <Text>{record?.name}</Text>

          <Title level={5}>{t("companies.fields.address")}</Title>
          <Text>{record?.address}</Text>

          <Title level={5}>{t("companies.fields.taxNumber")}</Title>
          <Text>{record?.taxNumber}</Text>

          <Title level={5}>{t("companies.fields.euTaxNumber")}</Title>
          <Text>{record?.taxNumberEu}</Text>

          <Title level={5}>{t("companies.fields.companyNumber")}</Title>
          <Text>{record?.companyNumber}</Text>
        </Show>
      </Drawer>
    </>
  );
};
