import { AuthProvider, HttpError } from "@pankod/refine-core";
import { AuthHelper, MyToken } from "./helpers";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { TOKEN_KEY, REFRESH_KEY, ROLES_KEY } from "./constants";
const apiAuthProvider = (apiUrl: string) => {
  const axiosInstance = axios.create({
    headers: {
      Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
    },
  });
  /*const token = localStorage.getItem(TOKEN_KEY);
  axiosInstance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${token}`;
*/
  // Request interceptor for API calls
  axiosInstance.interceptors.request.use(
    (config) => {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  const apiAuthHelper = AuthHelper(apiUrl, axiosInstance);

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      const refreshToken = localStorage.getItem(REFRESH_KEY);
      //console.log( `${JSON.stringify(error.response)} ${error.response?.status} -- ${error.response?.data?.detail} `)
      //      alert(error)
      if (
        refreshToken &&
        (error.status == 401 || error.response?.status == 401) &&
        error.response?.data?.message == "Expired JWT Token" &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const { data, status } = await apiAuthHelper.refresh(refreshToken);

        if (status === 200) {
          localStorage.setItem(TOKEN_KEY, data.token);
          localStorage.setItem(REFRESH_KEY, data.refresh_token);
          const user = jwtDecode<MyToken>(data.token ?? "");
          localStorage.setItem(
            ROLES_KEY,
            JSON.stringify(user.roles || ["ROLE_GUEST"])
          );
          // set header axios instance
          axiosInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${data.token}`;

          return axiosInstance(originalRequest);
        }        
        return Promise.reject();
        //axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(TOKEN_KEY)}`;
        //return axiosInstance(originalRequest);
      } else {
        //alert(error)
        const customError: HttpError = {
          ...error,
          message:
            error.response?.data?.detail ||
            error.response?.data?.message ||
            error.response?.detail ||
            error.response?.message,
          statusCode: error.response?.status || error.status,
        };

        return Promise.reject(customError);
      }
    }
  );

  const authProvider: AuthProvider = {
    login: async ({ username, password }) => {
      const { data, status } = await apiAuthHelper.login(username, password);
      if (status === 200) {
        localStorage.setItem(TOKEN_KEY, data.token);
        localStorage.setItem(REFRESH_KEY, data.refresh_token);
        const user = jwtDecode<MyToken>(data.token ?? "");
        localStorage.setItem(
          ROLES_KEY,
          JSON.stringify(user.roles || ["ROLE_GUEST"])
        );
        // set header axios instance
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.token}`;
        /*                axiosInstance.defaults.headers = {
                    Authorization: `Bearer ${data.jwt}`,
                };
*/

        return Promise.resolve();
      }
      return Promise.reject();
    },
    logout: () => {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(REFRESH_KEY);
      localStorage.removeItem(ROLES_KEY);
      return Promise.resolve();
    },
    checkError: (error) => {
      /*
        if (error && error.statusCode === 401) {
        const token = localStorage.getItem(TOKEN_KEY);
        const refreshToken = localStorage.getItem(REFRESH_KEY);

        try {
          if (token && refreshToken) {
            const user = jwtDecode<MyToken>(token ?? "");
            if (user.exp > new Date().getTime() / 1000 - 10) {
              return Promise.resolve();
            } else {
              //const { data, status } = await apiAuthHelper.refresh(refreshToken);
              const resp = apiAuthHelper
                .refresh(refreshToken)
                .then(({ data, status }) => {
                  if (status === 200) {
                    localStorage.setItem(TOKEN_KEY, data.token);
                    localStorage.setItem(REFRESH_KEY, data.refresh_token);
                    const user = jwtDecode<MyToken>(data.token ?? "");
                    localStorage.setItem(
                      ROLES_KEY,
                      JSON.stringify(user.roles || ["ROLE_GUEST"])
                    );
                    // set header axios instance
                    axiosInstance.defaults.headers.common[
                      "Authorization"
                    ] = `Bearer ${data.token}`;

                    return Promise.resolve();
                  }
                  return Promise.reject();
                });
              return resp;
            }
          } else {
            return Promise.reject();
          }
        } catch (e) {
          // override possible jwtDecode error
          return Promise.reject();
        }
      }
*/
      return Promise.resolve();
    },

    checkAuth: () => {
      const token = localStorage.getItem(TOKEN_KEY);
      const refreshToken = localStorage.getItem(REFRESH_KEY);

      try {
        if (token && refreshToken) {
          const user = jwtDecode<MyToken>(token ?? "");
          if (user.exp > new Date().getTime() / 1000 - 10) {
            return Promise.resolve();
          } else {
            //const { data, status } = await apiAuthHelper.refresh(refreshToken);

            const resp = apiAuthHelper
              .refresh(refreshToken)
              .then(({ data, status }) => {
                if (status === 200) {
                  localStorage.setItem(TOKEN_KEY, data.token);
                  localStorage.setItem(REFRESH_KEY, data.refresh_token);
                  const user = jwtDecode<MyToken>(data.token ?? "");
                  localStorage.setItem(
                    ROLES_KEY,
                    JSON.stringify(user.roles || ["ROLE_GUEST"])
                  );
                  // set header axios instance
                  axiosInstance.defaults.headers.common[
                    "Authorization"
                  ] = `Bearer ${data.token}`;

                  return Promise.resolve();
                }
                return Promise.reject();
              });
            return resp;
          }
        } else {
          return Promise.reject();
        }
      } catch (e) {
        // override possible jwtDecode error
        return Promise.reject();
      }
    },
    getPermissions: () => {
      const token = localStorage.getItem(TOKEN_KEY);
      if (token) {
        const user = jwtDecode<MyToken>(token);
        return Promise.resolve(user.roles || ["ROLE_GUEST"]);
      }
      return Promise.reject();
    },

    getUserIdentity: async () => {
      const token = localStorage.getItem(TOKEN_KEY);

      if (!token) {
        return Promise.reject();
      }

      const user = jwtDecode<MyToken>(token);

      return Promise.resolve({
        id: user.id,
        name: user.username || "",
        fullName: user.name || "",
        roles: user.roles || ["ROLE_GUEST"],
      });
      /*  const { data, status } = await apiAuthHelper.me(token);
      if (status === 200) {
        const { id, username, email } = data;
        return Promise.resolve({
          id,
          username,
          email,
        });
      }
*/
      //      return Promise.reject();
    },
  };

  return {
    authProvider,
    axiosInstance,
  };
};

export default apiAuthProvider;
