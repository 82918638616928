import { useMemo } from "react";
import {
  useApiUrl,
  useCustom,
  useList,
  useTranslate,
} from "@pankod/refine-core";
import { Typography } from "@pankod/refine-antd";
import { IPartner } from "interfaces/partner";
import "./style.less";

export const PartnerCounter: React.FC = () => {
  const t = useTranslate();
  const API_URL = useApiUrl();

  // const url = `${API_URL}/partnerCounter`;
  /*   const { data, isLoading } = useCustom<{
        total: number;
        trend: number;
    }>({ url, method: "get" });
*/

  const { data: partnerData } = useList<IPartner>({
    resource: "partners",
    config: {
      filters: [        
        {
          field: "paginate",
          operator: "eq",
          value: false,
        },
      ],
    },
  });

  const { Text, Title } = Typography;

  return (
    <div className="partners-wrapper">
    <div className="header">
        <Title level={2} className="header__title">
            {t("dashboard.partnerCounter")}
        </Title>
    
        <div className="header__numbers">
            <Text strong>{partnerData?.data.length ?? 0}</Text>
        </div>
    </div>
    </div>
    
  );
};
