import {
  useGetLocale,
  useSetLocale,
  useGetIdentity,
  useLogout,
  useIsExistAuthentication,
  Authenticated,
  useTranslate,
} from "@pankod/refine-core";
import {
  AntdLayout,
  Space,
  Menu,
  Button,
  Icons,
  Dropdown,
  Avatar,
  Typography,
  Radio,
} from "@pankod/refine-antd";
import { useTranslation } from "react-i18next";

const { DownOutlined, UserOutlined } = Icons;
const { Text } = Typography;

export const Header: React.FC = () => {
  const { i18n } = useTranslation();
  const { mutate: logout } = useLogout();
  const isExistAuthentication = useIsExistAuthentication();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const { data: user } = useGetIdentity();
  const translate = useTranslate();

  const currentLocale = locale();

  const menu = (
    <Menu selectedKeys={[currentLocale]}>
      {[...(i18n.languages || [])].map((lang: string) => (
        <Menu.Item
          key={lang}
          onClick={() => {
            changeLanguage(lang);
            document.documentElement.lang = i18n.language;
          }}
          icon={
            <span style={{ marginRight: 8 }}>
              <Avatar size={16} src={`/images/flags/${lang}.svg`} />
            </span>
          }
        >
          {lang === "en" ? "English" : lang === "hu" ? "Magyar" : "German"}
        </Menu.Item>
      ))}
    </Menu>
  );

  const profileMenu = (
    <Menu>
      <Menu.Item
        key={"logout"}
        onClick={() => {
          logout();
        }}
      >
        {translate("buttons.logout", "Logout")}
      </Menu.Item>
    </Menu>
  );

  return (
    <AntdLayout.Header
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0px 24px",
        height: "64px",
        backgroundColor: "#FFF",
      }}
    >
      <Space style={{ marginLeft: "8px" }}>
        <Dropdown overlay={menu}>
          <Button type="link">
            <Space>
              <Avatar size={16} src={`/images/flags/${currentLocale}.svg`} />
              {currentLocale === "en"
                ? "English"
                : currentLocale === "hu"
                ? "Magyar"
                : "German"}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </Space>
      <Space style={{ marginLeft: "8px" }}>
        {user ? (
          <Dropdown overlay={profileMenu}>
            <Space>
              {user?.avatar ? (
                <Avatar size={30} src={user?.avatar} alt={user?.email} />
              ) : (
                <Avatar size={30} icon={<UserOutlined />} />
              )}
              {user?.name ? (
                <Button type="link" onClick={(e) => e.preventDefault()}>
                  {user?.firstname ? user.firstname : user.name}{" "}
                  <DownOutlined />
                </Button>
              ) : (
                <></>
              )}
            </Space>
          </Dropdown>
        ) : (
          <Button
            type="link"
            onClick={() => {
              logout();
            }}
          >
            {translate("buttons.login", "Login")}
          </Button>
        )}
      </Space>

      {/*}  <Space style={{ marginLeft: "8px" }}>
        {user?.name && (
          <Text ellipsis strong>
            {user.name}
          </Text>
        )}
        {user?.avatar && <Avatar src={user?.avatar} alt={user?.name} />}
      </Space>
        */}
    </AntdLayout.Header>
  );
};
