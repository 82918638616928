import { useTranslate, useApiUrl, useShow } from "@pankod/refine-core";

import {
  Create,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  ButtonProps,
  Typography,
  Upload,
  Grid,
  getValueFromEvent,
  useSelect,
  List,
  Table,
  Modal,
  EditButton,
  ShowButton,
  useTable,
  useModalForm,
  ModalProps,
  Row,
  Col,
  Cascader,
  useStepsForm,
  Button,
  SaveButton,
  Steps,
} from "@pankod/refine-antd";

import { ITechnicalDatasheet } from "interfaces/technicaldatasheet";
import { useState } from "react";
import {
  providerType,
  amperMenu,
  statusTypes,
} from "constants/technicalDatasheet";

const { Text, Title } = Typography;
const { Step } = Steps;

type CreateTechnicalDatasheetProps = {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  //createModalProps: ModalProps;
  initialValues: any;
};

export const CreateTechnicalDatasheet: React.FC<
  CreateTechnicalDatasheetProps
> = ({ saveButtonProps, drawerProps, formProps, initialValues }) => {
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const breakpoint = Grid.useBreakpoint();

  const {
    formProps: stepFormProps,
    gotoStep,
    current,
    stepsProps,
    queryResult,
  } = useStepsForm<ITechnicalDatasheet>({
    isBackValidate: true,
    resource: "technical_datasheets",
  });

  function onChange(value: any) {
    console.log(value);
  }

  const formList = [
    <>
      <Row gutter={20}>
        <Col xs={24} lg={30}>
          <Row gutter={20}>
            <Col xs={24} lg={12}>
              <Form.Item
                hidden
                label={t("technical_datasheets.fields.project")}
                name="project"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input hidden />
              </Form.Item>
              <Form.Item
                label={t("technical_datasheets.fields.name")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("defaultValidateMessages.required", {
                      name: "${label}",
                    }),
                  },
                ]}
              >
                <Input tabIndex={1} />
              </Form.Item>

              <Form.Item
                label={t("technical_datasheets.fields.addressId")}
                name="address"
                rules={[
                  {
                    required: true,
                    message: t("defaultValidateMessages.required", {
                      name: "${label}",
                    }),
                  },
                ]}
              >
                <Input tabIndex={3} />
              </Form.Item>

              <Form.Item
                label={t("technical_datasheets.fields.providerPartnerNumber")}
                name="providerPartnerNumber"
                rules={[
                  {
                    required: true,
                    message: t("defaultValidateMessages.required", {
                      name: "${label}",
                    }),
                  },
                ]}
              >
                <Input tabIndex={5} />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item
                label={t("technical_datasheets.fields.providerContractNumber")}
                name="providerContractNumber"
                rules={[
                  {
                    required: true,
                    message: t("defaultValidateMessages.required", {
                      name: "${label}",
                    }),
                  },
                ]}
              >
                <Input tabIndex={2} />
              </Form.Item>

              <Form.Item
                label={t("technical_datasheets.fields.status")}
                name="status"
              >
                <Select
                  tabIndex={4}
                  options={Object.values(statusTypes).map((p) => ({
                    label: t(`${p.label}`),
                    value: p.value,
                  }))}
                  onChange={onChange}
                  placeholder={t("buttons.pleaseSelect")}
                />
              </Form.Item>

              <Form.Item
                label={t("technical_datasheets.fields.providerType")}
                name="providerType"
              >
                <Select
                  tabIndex={6}
                  options={providerType.map((p) => ({
                    label: t(`${p.label}`),
                    value: p.value,
                  }))}
                  onChange={onChange}
                  placeholder={t("buttons.pleaseSelect")}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </>,
    <Row gutter={20}>
      <Col xs={24} lg={30}>
        <Row gutter={10}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={t("technical_datasheets.fields.podNumber")}
              name="podNumber"
              rules={[
                {
                  required: true,
                  message: t("defaultValidateMessages.required", {
                    name: "${label}",
                  }),
                },
              ]}
            >
              <Input tabIndex={1} />
            </Form.Item>

            <Form.Item
              label={t("technical_datasheets.fields.providerMeasuringClock")}
              name="providerMeasuringClock"
              rules={[
                {
                  required: true,
                  message: t("defaultValidateMessages.required", {
                    name: "${label}",
                  }),
                },
              ]}
            >
              <InputNumber tabIndex={3}                
               style={{width: '100%' }}
               controls={false}
               formatter={(value) =>
                `${value}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )
              }/>
            </Form.Item>

            <Form.Item
              label={t("technical_datasheets.fields.phaseR")}
              name="phaseR"
            >
              <Select
                tabIndex={5}
                options={amperMenu.map((p) => ({
                  label: t(`${p.label}`),
                  value: p.value,
                }))}
                onChange={onChange}
                placeholder={t("buttons.pleaseSelect")}
              />
            </Form.Item>

            <Form.Item
              label={t("technical_datasheets.fields.phaseS")}
              name="phaseS"
            >
              {/*          <Cascader options={amperMenu} onChange={onChange} placeholder={t("buttons.pleaseSelect")} />*/}
              <Select
                tabIndex={7}
                options={amperMenu.map((p) => ({
                  label: t(`${p.label}`),
                  value: p.value,
                }))}
                onChange={onChange}
                placeholder={t("buttons.pleaseSelect")}
              />
            </Form.Item>

            <Form.Item
              label={t("technical_datasheets.fields.phaseT")}
              name="phaseT"
            >
              <Select
                tabIndex={9}
                options={amperMenu.map((p) => ({
                  label: t(`${p.label}`),
                  value: p.value,
                }))}
                onChange={onChange}
                placeholder={t("buttons.pleaseSelect")}
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label={t("technical_datasheets.fields.plannedConsumptionkWh")}
              name="plannedConsumptionkWh"
              rules={[
                {
                  type:"number",
                  message: t("defaultValidateMessages.notValidPlannedConsumptionkWh")
                },
                {
                  required: true,
                  message: t("defaultValidateMessages.required", {
                    name: "${label}",
                  }),
                },
              ]}
            >
              <InputNumber tabIndex={2}
               addonAfter={"kWh"}                
               style={{width: '100%' }}
               controls={false}
               formatter={(value) =>
                `${value}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )
              }/>
            </Form.Item>

            <Form.Item
              label={t("technical_datasheets.fields.yearlyConsumptionkWh")}
              name="yearlyConsumptionkWh"
              rules={[
                {
                  required: true,
                  message: t("defaultValidateMessages.required", {
                    name: "${label}",
                  }),
                },
              ]}
            >
              <InputNumber tabIndex={4}
               addonAfter={"kWh"}                
               style={{width: '100%' }}
               controls={false}
               formatter={(value) =>
                `${value}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )
              }/>
            </Form.Item>

            <Form.Item
              label={t("technical_datasheets.fields.plannedConsumptionFt")}
              name="plannedConsumptionFt"
              rules={[
                {
                  required: true,
                  message: t("defaultValidateMessages.required", {
                    name: "${label}",
                  }),
                },
              ]}
            >
              <InputNumber tabIndex={6}
               addonAfter={"Ft"}                
               style={{width: '100%' }}
               controls={false}
               formatter={(value) =>
                `${value}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )
              }/>
            </Form.Item>

            <Form.Item
              label={t("technical_datasheets.fields.yearlyConsumptionFt")}
              name="yearlyConsumptionFt"
              rules={[
                {
                  required: true,
                  message: t("defaultValidateMessages.required", {
                    name: "${label}",
                  }),
                },
              ]}
            >
              <InputNumber tabIndex={8}
               addonAfter={"Ft"}                
               style={{width: '100%' }}
               controls={false}
               formatter={(value) =>
                `${value}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )
              }/>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>,
  ];

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "700px" : "100%"}
      bodyStyle={{ padding: 0 }}
      onClose={(e) => {
        formProps.form?.resetFields();
        gotoStep(0);
        drawerProps.onClose && drawerProps.onClose(e);
      }}
    >
      <Create
        resource="technical_datasheets"
        // saveButtonProps={{ ...saveButtonProps }}
        actionButtons={
          <>
            {current > 0 && (
              <Button
                onClick={() => {
                  gotoStep(current - 1);
                }}
              >
                {t("buttons.previous")}
              </Button>
            )}
            {current < formList.length - 1 && (
              <Button
                onClick={() => {
                  formProps?.form?.validateFields().then(() => {
                    gotoStep(current + 1);
                  });
                }}
              >
                {t("buttons.next")}
              </Button>
            )}
            {current === formList.length - 1 && (
              <SaveButton
                {...saveButtonProps}
                style={{ marginRight: 10 }}
                //onClick={() => alert(JSON.stringify(formProps.form?.getFieldsValue(true)))}
              />
            )}
          </>
        }
      >
        <Steps {...stepsProps}>
          <Step title={t("technical_datasheets.titles.basicInformation")} />
          <Step title={t("technical_datasheets.titles.technicalData")} />
        </Steps>

        <Form
          {...stepFormProps}
          {...formProps}
          initialValues={{
            ...initialValues,
          }}
          layout="vertical"
          style={{ marginTop: 30 }}
          onFinish={(values) => {
            values = formProps.form?.getFieldsValue(true);
            return formProps.onFinish?.(values);
          }}
        >
          {formList[current]}
        </Form>
      </Create>
    </Drawer>
  );
};
