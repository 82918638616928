import React, { useState } from "react";
import { Button, ButtonProps, Popconfirm, Select } from "@pankod/refine-antd";
import { EditOutlined } from "@ant-design/icons";
import { useCan, useTranslate, useUpdate } from "@pankod/refine-core";
import { IProject } from "interfaces/project";
import { projectStatus } from "constants/project";
import dayjs from "dayjs";


export type SetStatusButtonProps = ButtonProps & {
  record?: IProject;
  hideText?: boolean;
  ignoreAccessControlProvider?: boolean;
};

/**
 * `<SetStatusButton>` uses Ant Design's {@link https://ant.design/components/button/ `<Button>`} component.
 */
export const SetStatusButton: React.FC<SetStatusButtonProps> = ({
  record,
  hideText = false,
  ignoreAccessControlProvider = false,
  children,
  onClick,
  ...rest
}) => {
  const [prStatus, setPrStatus] = useState(record?.status);
  const translate = useTranslate();
  const resourceName = "projects";
  const { mutate } = useUpdate();
  const setStatus = () => {
    if (record && record.status !== prStatus) {
      record?.events.find((el) => el.status === record?.status)?.id &&
        mutate({
          resource: "project_events",
          id:
            record?.events.find((el) => el.status === record?.status)?.id || 0,
          values: {
            date: dayjs().format(),
          },
          successNotification: false,
        });
      mutate({
        resource: resourceName,
        id: record?.id,
        values: {
          status: prStatus,
        },
        successNotification: false,
      });
    }
  };
  const { data } = useCan({
    resource: resourceName,
    action: "edit",
    params: { id: record?.id },
    queryOptions: {
      enabled: !ignoreAccessControlProvider,
    },
  });

  const buttonDisabledTitle = () => {
    if (data?.can) return "";
    else if (data?.reason) return data.reason;
    else
      return translate(
        "buttons.notAccessTitle"
      );
  };

  const renderTitle = () => {
    return (
      <Select
        defaultValue={record?.status}
        style={{ width: "12rem" }}
        onChange={(value) => setPrStatus(value)}
        options={Object.values(projectStatus).map((p) => ({
          label: translate(`${p.label}`),
          value: p.value,
        }))}
      />
    );
  };
  return (
    <>
      <Popconfirm
        title={renderTitle}
        placement="bottom"
        disabled={data?.can === false}
        okType="danger"
        okText={translate("projects.buttons.okButton")}
        onConfirm={() => {
          setStatus();
        }}
        cancelText={translate("projects.buttons.noButton")}
      >
        <Button
          danger
          type="primary"
          size="small"
          icon={<EditOutlined />}
          disabled={data?.can === false}
          title={buttonDisabledTitle()}
          {...rest}
        >
          {!hideText &&
            (children ??
              translate("projects.buttons.setStatus"))}
        </Button>
      </Popconfirm>
    </>
  );
};
