import {
  useTranslate,
} from "@pankod/refine-core";
import { IPublicAreaType } from "../interfaces/address";


export const addressTypes =
 [
  {
    value: "headquarter",
    label: "headquarter"
  },
  {
    value: "mailing",
    label: "mailing",
  },
  {
    value: "delivery",
    label: "delivery",
  },
  
];


export const publicAreaTypes =
 [
  {
    value: "street",
    label: "street"
  },
  {
    value: "road",
    label: "road",
  },
  {
    value: "path",
    label: "path",
  },
  {
    value: "square",
    label: "square",
  },

];

