import {
  List,
  Table,
  useTable,
  Modal,
  Show,
  ShowButton,
  Typography,
  Col,
  Row,
  FileField,
  Button,
  DateField,
  RefreshButton,
} from "@pankod/refine-antd";
import { useApiUrl, useTranslate, useShow } from "@pankod/refine-core";
import { Statuses } from "components/common";
import { IProject } from "interfaces/project";
import { ITenders } from "interfaces/tenders";
import { useState } from "react";

const { Title, Text } = Typography;

export const ProjectTendersTable: React.FC<{ record: IProject }> = ({
  record: project,
}) => {
  const API_URL = useApiUrl();
  const t = useTranslate();
  const [visible, setVisible] = useState(false);

  const { tableProps: tendersableProps } = useTable<ITenders>({
    resource: "tenders",
    permanentFilter: [
      {
        field: "project.id",
        operator: "eq",
        value: project.id,
      },
    ],
    syncWithLocation: false,
  });

  const { queryResult, showId, setShowId } = useShow<ITenders>({
    resource: "tenders",
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;
  return (
    <>
      <List title={t("tenders.tenders")}>
        <Table {...tendersableProps} rowKey="id">
          <Table.Column
            key="name"
            dataIndex="name"
            title={t("tenders.fields.name")}
          />

          <Table.Column<ITenders>
            dataIndex="status"
            title={t("price_offers.fields.status")}
            render={(value) => {
              return <Statuses status={value} />;
            }}
          />
          <Table.Column<ITenders>
            title="Actions"
            dataIndex="actions"
            render={(_, record) => (
              <ShowButton
                size="small"
                recordItemId={record.id}
                onClick={() => {
                  setShowId(record.id);
                  setVisible(true);
                }}
              />
            )}
          />
        </Table>
      </List>
      <Modal visible={visible} closable={false} footer={false} width={800}>
        <Show
          isLoading={isLoading}
          pageHeaderProps={{
            onBack: () => setVisible(false),
            extra: <RefreshButton hidden/>
          }}
          resource="tenders"
          recordItemId={showId}
        >
          <Row gutter={20}>
            <Col xs={24} lg={30}>
              <Row gutter={10}>
                <Col xs={24} lg={12}>
                  <Title level={5}>{t("tenders.fields.name")}</Title>
                  <Text>{record?.name}</Text>

                  <Title level={5}>{t("tenders.fields.status")}</Title>
                  <Text>{t(`statusTypes.${record?.status}`)}</Text>

                  <Title level={5}>{t("tenders.fields.date")}</Title>
                  <DateField value={record?.startDate} format="LL" />
                </Col>
                <Col xs={24} lg={12}>
                  {/*<FileField src={record.image[0].url} />*/}

                  <Title level={5}>{t("tenders.fields.description")}</Title>
                  <Text>{record?.description}</Text>

                  <Title level={5}>{t("tenders.fields.type")}</Title>
                  <Text>{record?.type}</Text>

                  <Title level={5}>{t("tenders.fields.file")}</Title>
                  <Row gutter={10}>
                    {record?.images?.map((data: any) => {
                      return (
                        <Col xs={24} lg={24}>
                          <FileField
                            target="_blank"
                            title={data?.name}
                            src={data?.url}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Show>
      </Modal>
    </>
  );
};
