import { useTranslate, IResourceComponentsProps, useApiUrl } from "@pankod/refine-core";
import {
  useForm,
  Form,
  Input,
  Select,
  Edit,
  useSelect,
} from "@pankod/refine-antd";
import { ILocation } from "interfaces/location";
import { ICountries } from "interfaces/countries";

export const LocationCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<ILocation>();
  const API_URL = useApiUrl();
  const {
    selectProps: countrySelectProps,
    queryResult: countryQueryResult,
    defaultValueQueryResult: countryDefaultValueQueryResult,
  } = useSelect<ICountries>({
    resource: "countries",
  });

  const allCountriesOptions = [
    ...(countryQueryResult.data?.data || []),
    ...(countryDefaultValueQueryResult.data?.data || []),
  ];
  const t = useTranslate();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item label={t("locations.fields.postcode")} name="postcode">
          <Input />
        </Form.Item>
        <Form.Item label={t("palocationsrtners.fields.name")} name="name">
          <Input />
        </Form.Item>
        <Form.Item label={t("locations.fields.country")} name="country">
          <Input />
        </Form.Item>

        <Form.Item
          label={t("locations.fields.country")}
          name={["country", "id"]}
          rules={[{ required: true, message: "Missing location" }]}
        >
         <Select
                {...countrySelectProps}
                options={allCountriesOptions.map((c) => ({                
                  label: `${c.title}`,
                  value: c.id,
              //    value: `${API_URL}/countries/${c.id}`,
                }))}
              />
        
        </Form.Item>
      </Form>
    </Edit>
  );
};
