export const statusTypes = {
  live: {
    value: "live",
    label: "statusTypes.live",
  },
  accepted: {
    value: "accepted",
    label: "statusTypes.accepted",
  },
  closed: {
    value: "closed",
    label: "statusTypes.closed",
  },
};
