export const projectStatusColor = (
  id: string
): { color: string; text: string } | undefined => {
  switch (id) {
    case "technical_datasheets":
      return { color: "orange", text: "pending" };
    case "price_offers":
      return { color: "cyan", text: "ready" };
    case "contracts":
      return { color: "cyan", text: "ready" };
    case "provider_contracts":
      return { color: "cyan", text: "ready" };
    case "tenders":
      return { color: "blue", text: "delivered" };
    case "finished":
      return { color: "green", text: "on the way" };

    case "rejected":
      return { color: "red", text: "cancelled" };
    default:
      break;
  }
};

export const projectStatus = {
  technical_datasheets: {
    value: "technical_datasheets",
    label: "projects.statuses.technical_datasheets",
  },
  price_offers: {
    value: "price_offers",
    label: "projects.statuses.price_offers",
  },
  contracts: {
    value: "contracts",
    label: "projects.statuses.contracts",
  },
  provider_contracts: {
    value: "provider_contracts",
    label: "projects.statuses.provider_contracts",
  },
  tenders: {
    value: "tenders",
    label: "projects.statuses.tenders",
  },
  finished: {
    value: "finished",
    label: "projects.statuses.finished",
  },
  rejected: {
    value: "rejected",
    label: "projects.statuses.rejected",
  },
};
