import { Row, Col, Card, Typography } from "@pankod/refine-antd";
import { useTranslation } from "react-i18next";

import { PartnerCounter } from "components/dashboard";
import { ProjectCounter } from "components/dashboard/projectCounter";
import { OrderTimeline } from "components/dashboard/orderTimeline";
import { ProjectTimeline } from "components/dashboard/projectTimeline";
import { Button, notification, Divider, Space } from "antd";
import {
  RadiusUpleftOutlined,
  RadiusUprightOutlined,
  RadiusBottomleftOutlined,
  RadiusBottomrightOutlined,
  BorderTopOutlined,
  BorderBottomOutlined,
} from "@ant-design/icons";
import { useGetIdentity, useList, useShow } from "@pankod/refine-core";
import { IProject } from "interfaces/project";
import { useEffect } from "react";
import { IUser } from "interfaces/user";

const { Text } = Typography;

export const DashboardPage: React.FC = () => {
  const { t } = useTranslation();

  const openNotification = (placement: any) => {
    notification.info({
      message: <h3>{t("buttons.notificationTitle")}</h3>,
      description: (
        <>
          <p>
            <b>
              {t("buttons.maxProjectMessage")}
            </b>
          </p>
          <p>
            <b>{t("buttons.maxProjectMessageContact")}</b>
          </p>
          <p>
            <b>{t("buttons.maxProjectMessageEmail")}</b>
          </p>
          <p>
            <b>{t("buttons.maxProjectMessagePhone")}</b>
          </p>
        </>
      ),
      placement,
      duration: 0,
    });
  };

  const openNotification2 = (placement: any) => {
    notification.info({
      message: <h3>{t("buttons.notificationTitle")}</h3>,
      description: (
        <>
          <p>
            <b>
              {t("buttons.maxProjectMessage2")}
            </b>
          </p>
          <p>
            <b>{t("buttons.maxProjectMessageContact")}</b>
          </p>
          <p>
            <b>{t("buttons.maxProjectMessageEmail")}</b>
          </p>
          <p>
            <b>{t("buttons.maxProjectMessagePhone")}</b>
          </p>
        </>
      ),
      placement,
      duration: 0,
    });
  };

  const { data: projectData } = useList<IProject>({
    resource: "projects",
    config: {
      filters: [
        {
          field: "paginate",
          operator: "eq",
          value: false,
        },
      ],
    },
  });

  const { data: user } = useGetIdentity();
  const { setShowId: setUserShowId, queryResult: companyQuery } =
    useShow<IUser>({
      resource: "users",
      id: user?.id,
    });
  const { data: companyData } = companyQuery;
  const companyRecord = companyData?.data;

  useEffect(() => {
    setUserShowId(user?.id);
  }, [user]);

  useEffect(() => {
    if (
      companyRecord?.maxProjectNumber &&
      projectData?.data?.length &&
      companyRecord?.maxProjectNumber - projectData?.data?.length <= 0
    ) {
      openNotification("top");
    } else {
      if (
        companyRecord?.maxProjectNumber &&
        projectData?.data?.length &&
        companyRecord?.maxProjectNumber - projectData?.data?.length <= 5
      ) {
        openNotification2("top");
      }
    }
  }, [projectData]);

  return (
    <Row gutter={[16, 16]}>
      <Col md={24}>
        <Row gutter={[16, 16]}>
          <Col xl={10} lg={24} md={24} sm={24} xs={24}>
            <Card
              bodyStyle={{
                padding: 10,
                paddingBottom: 0,
              }}
              style={{
                background: "",
                backgroundColor: "#001529",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right",
              }}
            >
              <PartnerCounter />
            </Card>
          </Col>
          <Col xl={7} lg={12} md={24} sm={24} xs={24}>
            <Card
              bodyStyle={{
                padding: 10,
                paddingBottom: 0,
              }}
              style={{
                background: "",
                backgroundColor: "#001529",
                backgroundRepeat: "no-repeat",
              }}
            >
              <ProjectCounter />
            </Card>
          </Col>
          <Col xl={7} lg={8} md={24} sm={24} xs={24}>
            <Card
              bodyStyle={{
                height: 550,
                overflowY: "scroll",
              }}
              title={<Text strong>{t("dashboard.timelineTitle")}</Text>}
            >
              <ProjectTimeline />
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
