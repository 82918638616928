import axios, { AxiosInstance } from "axios";

export interface MyToken {
  id: string;
  username: string;
  email: string;
  name: string;
  exp: number;
  roles: [];
  // whatever else is in the JWT.
}

interface ILoginResponse {
  // jwt: string;
  //user: IUser;
  token: string;
  refresh_token: string;
}

interface IRole {
  id: number | string;
  name: string;
  description: string;
  type: string;
}

interface IUser {
  id: number | string;
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked: boolean;
  role: IRole;
  created_at: string;
  updated_at: string;
}

export const AuthHelper = (apiUrl: string,httpClient: AxiosInstance) => ({
  login: async (email: string, password: string) => {
    const url = `${apiUrl}/token`;

    return await httpClient.post<ILoginResponse>(url, {
      email,
      password,
    });
  },
  refresh: async (refresh_token: string) => {
    const url = `${apiUrl}/token/refresh`;

    return  await httpClient.post<ILoginResponse>(url, {
      refresh_token,
    });
  },
  me: async (token: string) => {
    return await httpClient.get<IUser>(`${apiUrl}/users/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
});
