export const amperMenu = [
  {
    value: "6",
    label: "6A",
  },
  {
    value: "10",
    label: "10A",
  },
  {
    value: "16",
    label: "16A",
  },
  {
    value: "20",
    label: "20A",
  },
  {
    value: "25",
    label: "25A",
  },
  {
    value: "32",
    label: "32A",
  },
  {
    value: "40",
    label: "40A",
  },
  {
    value: "50",
    label: "50A",
  },
  {
    value: "63",
    label: "63A",
  },

  {
    value: "80",
    label: "80A",
  },
  {
    value: "100",
    label: "100A",
  },
  {
    value: "125",
    label: "125A",
  },
  {
    value: "160",
    label: "160A",
  },
  {
    value: "200",
    label: "200A",
  },
  {
    value: "224",
    label: "224A",
  },
  {
    value: "250",
    label: "250A",
  },
  {
    value: "280",
    label: "280A",
  },
  {
    value: "300",
    label: "300A",
  },
  {
    value: "315",
    label: "315A",
  },
  {
    value: "355",
    label: "355A",
  },
  {
    value: "400",
    label: "400A",
  },
  {
    value: "425",
    label: "425A",
  },
  {
    value: "500",
    label: "500A",
  },
  {
    value: "560",
    label: "560A",
  },
  {
    value: "630",
    label: "630A",
  },
];

export const providerType = [
  {
    value: "1",
    label: "technical_datasheets.fields.providerTypeTrue",
  },
  {
    value: "2",
    label: "technical_datasheets.fields.providerTypeFalse",
  },
];

export const statusTypes = {
  inProgress: {
    value: "inProgress",
    label: "technical_datasheets.statusTypes.inProgress",
  },
  actual: {
    value: "actual",
    label: "technical_datasheets.statusTypes.actual",
  },
  invalid: {
    value: "invalid",
    label: "technical_datasheets.statusTypes.invalid",
  },
};
