import { useTranslate } from "@pankod/refine-core";

import {
  Avatar,
  Card,
  Divider,
  InputNumber,
  Icons,
  Dropdown,
  Menu,
  NumberField,
  Typography,
} from "@pankod/refine-antd";

import { ITechnicalDatasheet } from "../../interfaces/technicaldatasheet";

const { Text, Paragraph } = Typography;
const { CloseCircleOutlined, FormOutlined } = Icons;



type TechnicalDatasheetItemProps = {
  item: ITechnicalDatasheet;
  editShow: (id?: string | undefined) => void;
};

export const TechnicalDatasheetItem: React.FC<TechnicalDatasheetItemProps> = ({
  item,
  editShow,
}) => {
  const t = useTranslate();

  return (
    <Card
      style={{
        margin: "8px",        
      }}
      bodyStyle={{ height: "500px" }}
    >
      <div style={{ position: "absolute", top: "10px", right: "5px" }}>
        <Dropdown
          overlay={
            <Menu mode="vertical">
              <Menu.Item
                key="2"
                style={{
                  fontWeight: 500,
                }}
                icon={
                  <FormOutlined
                    style={{
                      color: "green",
                    }}
                  />
                }
                onClick={() => editShow(item.id)}
              >
                {t("stores.buttons.edit")}
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Icons.MoreOutlined
            style={{
              fontSize: 24,
            }}
          />
        </Dropdown>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Paragraph
          ellipsis={{ rows: 2, tooltip: true }}
          style={{
            fontSize: "18px",
            fontWeight: 800,
            marginBottom: "8px",
          }}
        >
          {item.name}
        </Paragraph>

        <Text
          className="item-id"
          style={{
            fontSize: "18px",
            fontWeight: 700,
            color: "#999999",
          }}
        >
          #{item.id}
        </Text>
      </div>
    </Card>
  );
};
