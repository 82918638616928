import {
  useTranslate,
  IResourceComponentsProps,
  useApiUrl,
  useShow,
  useUpdate,
} from "@pankod/refine-core";

import {
  Form,
  Input,
  Steps,
  Select,
  Edit,
  useSelect,
  InputNumber,
  useForm,
  useTable,
  SaveButton,
  Tabs,
  List,
  Table,
  Space,
  BooleanField,
  useEditableTable,
  Checkbox,
  Icons,
  Avatar,
  NumberField,
  DateField,
  Grid,
  CreateButton,
  EditButton,
  ShowButton,
  useDrawerForm,
  Typography,
  DrawerProps,
  FormProps,
  ButtonProps,
  useModalForm,
  Radio,
  Modal,
  Row,
  Col,
  useStepsForm,
  PageHeader,
  Progress,
  ListButton,
  RefreshButton,
  TextField,
} from "@pankod/refine-antd";

import { IEvent, IProject } from "interfaces/project";
import {
  Menu,
  Dropdown,
  Button,
  Tag,
  Skeleton,
  Alert,
  notification,
} from "antd";
import { Cascader } from "antd";
import dayjs from "dayjs";

import { ITechnicalDatasheet } from "interfaces/technicaldatasheet";
import { IPriceOffer } from "interfaces/priceoffer";
import { CreatePriceOffer, EditPriceOffer } from "components/priceOffers";
import { ProjectTechnicalDatasheetsTable } from "components/projects/technicalDatasheets/projectTechnicalDatasheetsTable";

import { CreateContract, EditContract } from "components/contracts";

import {
  CreateProviderContracts,
  EditProviderContracts,
} from "components/providerContract";

import { CreateTenders, EditTenders } from "components/tenders";
import "styles/solar.less";

import {
  CreateTechnicalDatasheet,
  EditTechnicalDatasheet,
} from "components/technicaldatasheets";
import { IContract } from "interfaces/contract";
import { IProviderContract } from "interfaces/providercontract";
import { ITenders } from "interfaces/tenders";
import { IPartner } from "interfaces/partner";
import { IUser } from "interfaces/user";
import { IProjectEvents } from "interfaces/projectEvents";
import { flatten } from "dataProvider";
import { TechnicalDatasheetStatus } from "components/technicalDatasheetStatus";
import { Statuses } from "components/common";
import { projectStatus } from "constants/project";
import { useEffect, useState } from "react";
import { ProjectPriceOffersTable } from "components/projects/priceOffers/projectPriceOffersTable";
import { ProjectContractsTable } from "components/projects/contracts/projectContractsTable";
import { ProjectProviderContractTable } from "components/projects/providerContracts/projectProviderContractTable";
import { ProjectTendersTable } from "components/projects/tenders/projectTendersTable";
import { SetStatusButton } from "components/projects/SetStatusButton";
import { PartnerSelect } from "components/partner/PartnersSelect";
const LoadingIcon = (
  <svg
    role="status"
    className="mr-2 h-4 w-4 animate-spin fill-blue-600 text-gray-200"
    viewBox="0 0 100 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
      fill="currentColor"
    />
    <path
      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
      fill="currentFill"
    />
  </svg>
);

const { Text, Title } = Typography;
const { Step } = Steps;
type CreateTechnicalDatasheetProps = {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  initialValues: any;
};
const { useBreakpoint } = Grid;

export const ProjectEdit: React.FC = () => {
  const { formLoading, formProps, saveButtonProps, queryResult } =
    useForm<IProject>({
      resource: "projects",
      redirect: false,
    });
  const t = useTranslate();
  const postData = queryResult?.data?.data;
  // const { tableProps } = useTable<ITechnicalDatasheet>();

  const {
    selectProps: partnerSelectProps,
    queryResult: partnerQueryResult,
    defaultValueQueryResult: partnerDefaultValueQueryResult,
  } = useSelect<IPartner>({
    resource: "partners",
    onSearch: (value) => [
      {
        field: "name",
        operator: "eq",
        value,
      },
    ],
  });

  const {
    selectProps: userSelectProps,
    queryResult: userQueryResult,
    defaultValueQueryResult: userDefaultValueQueryResult,
  } = useSelect<IUser>({
    resource: "users",
  });

  const userAllOptions = [
    ...(userQueryResult.data?.data || []),
    ...(userDefaultValueQueryResult.data?.data || []),
  ];

  const partnerAllOptions = [
    ...(partnerQueryResult.data?.data || []),
    ...(partnerDefaultValueQueryResult.data?.data || []),
  ];

  const { queryResult: eventQueryResult } = useShow<IProject>();
  const { data } = eventQueryResult;
  const { mutate } = useUpdate();
  const record = data?.data;
  const [activeKey, setActiveKey] = useState(record?.status);
  const onTabChange = (activeKey: any) => {
    setActiveKey(activeKey);
  };
  const screens = useBreakpoint();
  const currentBreakPoints = Object.entries(screens)
    .filter((screen) => !!screen[1])
    .map((screen) => screen[0]);

  const renderProjectSteps = () => {
    const notFinishedCurrentStep = (event: IProjectEvents, index: number) => {
      return (
        event.status !== projectStatus.rejected.value &&
        event.status !== projectStatus.finished.value &&
        record?.events.findIndex((el) => el.status === record?.status) === index
      );
    };

    const stepStatus = (event: IProjectEvents, index: number) => {
      if (!event.date) return "wait";
      if (event.status === projectStatus.rejected.value) return "error";
      if (notFinishedCurrentStep(event, index)) return "process";
      return "finish";
    };

    const close = () => {
      console.log(
        "Notification was closed. Either the close button was clicked or duration time elapsed."
      );
    };

    const openNotification = () => {
      const key = `open${Date.now()}`;
      const btn = (
        <Button
          key="projectStatus.price_offers"
          type="primary"
          size="small" /*onClick={() => notification.close(key)}*/
          disabled={record?.status !== projectStatus.technical_datasheets.value}
          hidden={
            record?.status === projectStatus.rejected.value ||
            record?.status === projectStatus.contracts.value ||
            record?.status === projectStatus.provider_contracts.value ||
            record?.status === projectStatus.tenders.value ||
            record?.status === projectStatus.price_offers.value ||
            record?.status === projectStatus.finished.value
          }
          icon={<Icons.CheckCircleOutlined />}
          onClick={() => {
            notification.close(key);
            if (record) {
              record?.events.find((el) => el.status === record?.status)?.id &&
                mutate({
                  resource: "project_events",
                  id:
                    record?.events.find((el) => el.status === record?.status)
                      ?.id || 0,
                  values: {
                    date: dayjs().format(),
                  },
                  successNotification: false,
                });
              mutate({
                resource: "projects",
                id: record?.id,
                values: {
                  status: projectStatus.price_offers.value,
                },
                successNotification: false,
              });
            }
          }}
        >
          {t("buttons.projectAccept2")}
        </Button>
      );
      notification.open({
        message: t("buttons.notificationTitle"),
        description: t("buttons.notifDesc"),
        btn,
        key,
        onClose: close,
      });
    };

    const openNotification2 = () => {
      const key = `open${Date.now()}`;
      const btn = (
        <Button
          key="projectStatus.contracts"
          size="small"
          disabled={record?.status !== projectStatus.price_offers.value}
          hidden={
            record?.status === projectStatus.rejected.value ||
            record?.status === projectStatus.contracts.value ||
            record?.status === projectStatus.technical_datasheets.value ||
            record?.status === projectStatus.provider_contracts.value ||
            record?.status === projectStatus.tenders.value ||
            record?.status === projectStatus.finished.value
          }
          icon={<Icons.CheckCircleOutlined />}
          type="primary"
          onClick={() => {
            notification.close(key);
            if (record) {
              record?.events.find((el) => el.status === record?.status)?.id &&
                mutate({
                  resource: "project_events",
                  id:
                    record?.events.find((el) => el.status === record?.status)
                      ?.id || 0,
                  values: {
                    date: dayjs().format(),
                  },
                  successNotification: false,
                });
              mutate({
                resource: "projects",
                id: record?.id,
                values: {
                  status: projectStatus.contracts.value,
                },
                successNotification: false,
              });
            }
          }}
        >
          {t("buttons.projectAccept2")}
        </Button>
      );
      notification.open({
        message: t("buttons.notificationTitle"),
        description: t("buttons.notifDesc"),
        btn,
        key,
        onClose: close,
      });
    };

    const openNotification3 = () => {
      const key = `open${Date.now()}`;
      const btn = (
        <Button
          key="projectStatus.provider_contracts"
          size="small"
          disabled={record?.status !== projectStatus.contracts.value}
          hidden={
            record?.status === projectStatus.rejected.value ||
            record?.status === projectStatus.provider_contracts.value ||
            record?.status === projectStatus.technical_datasheets.value ||
            record?.status === projectStatus.price_offers.value ||
            record?.status === projectStatus.tenders.value ||
            record?.status === projectStatus.finished.value
          }
          icon={<Icons.CheckCircleOutlined />}
          type="primary"
          onClick={() => {
            notification.close(key);
            if (record) {
              record?.events.find((el) => el.status === record?.status)?.id &&
                mutate({
                  resource: "project_events",
                  id:
                    record?.events.find((el) => el.status === record?.status)
                      ?.id || 0,
                  values: {
                    date: dayjs().format(),
                  },
                  successNotification: false,
                });
              mutate({
                resource: "projects",
                id: record?.id,
                values: {
                  status: projectStatus.provider_contracts.value,
                },
                successNotification: false,
              });
            }
          }}
        >
          {t("buttons.projectAccept2")}
        </Button>
      );
      notification.open({
        message: t("buttons.notificationTitle"),
        description: t("buttons.notifDesc"),
        btn,
        key,
        onClose: close,
      });
    };

    const openNotification4 = () => {
      const key = `open${Date.now()}`;
      const btn = (
        <Button
          key="projectStatus.tenders"
          size="small"
          disabled={record?.status !== projectStatus.provider_contracts.value}
          hidden={
            record?.status === projectStatus.rejected.value ||
            record?.status === projectStatus.contracts.value ||
            record?.status === projectStatus.technical_datasheets.value ||
            record?.status === projectStatus.price_offers.value ||
            record?.status === projectStatus.tenders.value ||
            record?.status === projectStatus.finished.value
          }
          icon={<Icons.CheckCircleOutlined />}
          type="primary"
          onClick={() => {
            notification.close(key);
            if (record) {
              record?.events.find((el) => el.status === record?.status)?.id &&
                mutate({
                  resource: "project_events",
                  id:
                    record?.events.find((el) => el.status === record?.status)
                      ?.id || 0,
                  values: {
                    date: dayjs().format(),
                  },
                  successNotification: false,
                });
              mutate({
                resource: "projects",
                id: record?.id,
                values: {
                  status: projectStatus.tenders.value,
                },
                successNotification: false,
              });
            }
          }}
        >
          {t("buttons.projectAccept2")}
        </Button>
      );
      notification.open({
        message: t("buttons.notificationTitle"),
        description: t("buttons.notifDesc"),
        btn,
        key,
        onClose: close,
      });
    };

    const openNotification5 = () => {
      const key = `open${Date.now()}`;
      const btn = (
        <Button
          size="small"
          key="finished"
          danger
          disabled={record?.status !== projectStatus.tenders.value}
          hidden={
            record?.status === projectStatus.rejected.value ||
            record?.status === projectStatus.contracts.value ||
            record?.status === projectStatus.technical_datasheets.value ||
            record?.status === projectStatus.price_offers.value ||
            record?.status === projectStatus.finished.value ||
            record?.status === projectStatus.provider_contracts.value
          }
          icon={<Icons.CheckCircleOutlined />}
          type="primary"
          onClick={() => {
            notification.close(key);
            if (record) {
              record?.events.find((el) => el.status === record?.status)?.id &&
                mutate({
                  resource: "project_events",
                  id:
                    record?.events.find((el) => el.status === record?.status)
                      ?.id || 0,
                  values: {
                    date: dayjs().format(),
                  },
                  successNotification: false,
                });
              mutate({
                resource: "projects",
                id: record?.id,
                values: {
                  status: projectStatus.finished.value,
                },
                successNotification: false,
              });
            }
          }}
        >
          {t("buttons.projectAccept2")}
        </Button>
      );
      notification.open({
        message: t("buttons.notificationTitle"),
        description: t("buttons.notifDescFinish"),
        btn,
        key,
        onClose: close,
      });
    };

    const openNotification6 = () => {
      const key = `open${Date.now()}`;
      const btn = (
        <Button
          size="small"
          disabled={
            record?.status === projectStatus.rejected.value ||
            record?.status === projectStatus.finished.value
          }
          key="reject"
          danger
          icon={<Icons.CloseCircleOutlined />}
          onClick={() => {
            if (record) {
              record?.events.find((el) => el.status === record?.status)?.id &&
                mutate({
                  resource: "project_events",
                  id:
                    record?.events.find((el) => el.status === record?.status)
                      ?.id || 0,
                  values: {
                    date: dayjs().format(),
                  },
                  successNotification: false,
                });
              mutate({
                resource: "projects",
                id: record?.id,
                values: {
                  status: projectStatus.rejected.value,
                },
                successNotification: false,
              });
            }
          }}
        >
          {t("buttons.projectReject")}
        </Button>
      );
      notification.open({
        message: t("buttons.notificationTitle"),
        description: t("buttons.notifDescReject"),
        btn,
        key,
        onClose: close,
      });
    };

    return (
      <PageHeader
        className="pageHeader"
        ghost={false}
        title={t("projects.titles.projectStatus")}
        extra={[
          <SetStatusButton record={record}></SetStatusButton>,
          <Button
            key="accept1"
            size="small"
            type="primary"
            onClick={openNotification}
            disabled={
              record?.status !== projectStatus.technical_datasheets.value
            }
            hidden={
              record?.status === projectStatus.rejected.value ||
              record?.status === projectStatus.contracts.value ||
              record?.status === projectStatus.provider_contracts.value ||
              record?.status === projectStatus.tenders.value ||
              record?.status === projectStatus.price_offers.value ||
              record?.status === projectStatus.finished.value
            }
          >
            {t("buttons.projectAccept")}
          </Button>,

          <Button
            key="accept2"
            size="small"
            type="primary"
            onClick={openNotification2}
            disabled={record?.status !== projectStatus.price_offers.value}
            hidden={
              record?.status === projectStatus.rejected.value ||
              record?.status === projectStatus.contracts.value ||
              record?.status === projectStatus.technical_datasheets.value ||
              record?.status === projectStatus.provider_contracts.value ||
              record?.status === projectStatus.tenders.value ||
              record?.status === projectStatus.finished.value
            }
          >
            {t("buttons.projectAccept")}
          </Button>,

          <Button
            key="accept3"
            size="small"
            type="primary"
            onClick={openNotification3}
            disabled={record?.status !== projectStatus.contracts.value}
            hidden={
              record?.status === projectStatus.rejected.value ||
              record?.status === projectStatus.provider_contracts.value ||
              record?.status === projectStatus.technical_datasheets.value ||
              record?.status === projectStatus.price_offers.value ||
              record?.status === projectStatus.tenders.value ||
              record?.status === projectStatus.finished.value
            }
          >
            {t("buttons.projectAccept")}
          </Button>,

          <Button
            key="accept4"
            size="small"
            type="primary"
            onClick={openNotification4}
            disabled={record?.status !== projectStatus.provider_contracts.value}
            hidden={
              record?.status === projectStatus.rejected.value ||
              record?.status === projectStatus.contracts.value ||
              record?.status === projectStatus.technical_datasheets.value ||
              record?.status === projectStatus.price_offers.value ||
              record?.status === projectStatus.tenders.value ||
              record?.status === projectStatus.finished.value
            }
          >
            {t("buttons.projectAccept")}
          </Button>,

          <Button
            key="accept5"
            size="small"
            type="primary"
            onClick={openNotification5}
            disabled={record?.status !== projectStatus.tenders.value}
            hidden={
              record?.status === projectStatus.rejected.value ||
              record?.status === projectStatus.contracts.value ||
              record?.status === projectStatus.technical_datasheets.value ||
              record?.status === projectStatus.price_offers.value ||
              record?.status === projectStatus.finished.value ||
              record?.status === projectStatus.provider_contracts.value
            }
          >
            {t("buttons.projectAccept")}
          </Button>,

          <Button
            key="accept6"
            size="small"
            type="primary"
            onClick={openNotification6}
            disabled={
              record?.status === projectStatus.rejected.value ||
              record?.status === projectStatus.finished.value
            }
          >
            {t("buttons.projectReject")}
          </Button>,
        ]}
      >
        <Steps
          direction={
            currentBreakPoints.includes("lg") ? "horizontal" : "vertical"
          }
          current={record?.events.findIndex(
            (el) => el.status === record?.status
          )}
        >
          {record?.events.map((event: IProjectEvents, index: number) => (
            <Steps.Step
              status={stepStatus(event, index)}
              key={index}
              title={
                event?.status
                  ? t(`projects.statuses.${event?.status}`)
                  : t("unknown")
              }
              icon={
                notFinishedCurrentStep(event, index) && (
                  <Progress
                    type="circle"
                    width={40}
                    percent={30}
                    format={(percent) => (
                      <Text italic type="secondary" className="progressTitle">
                        {dayjs(event.date).fromNow()}
                      </Text>
                    )}
                  />
                )
              }
              description={event.date && dayjs(event.date).format("L")}
            />
          ))}
        </Steps>
        {!record && <Skeleton paragraph={{ rows: 1 }} />}
      </PageHeader>
    );
  };

  useEffect(() => {
    if (eventQueryResult.isSuccess && !eventQueryResult.isLoading) {
      setActiveKey(
        record?.status === projectStatus.finished.value ||
          record?.status === projectStatus.rejected.value
          ? projectStatus.technical_datasheets.value
          : record?.status
      );
    }
  }, [
    eventQueryResult.isSuccess,
    eventQueryResult.isRefetching,
    eventQueryResult.isLoading,
  ]);

  return (
    <Edit
      isLoading={formLoading}
      resource="projects"
      saveButtonProps={saveButtonProps}
      pageHeaderProps={{
        extra: (
          <>
            <RefreshButton hidden />
            <ListButton />
          </>
        ),
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values) => {
          values = {
            ...values,
            partner: flatten(formProps.form?.getFieldsValue(true)?.partner),
            agent: flatten(formProps.form?.getFieldsValue(true)?.agent),
          };
          //alert(JSON.stringify(flatten(formProps.form?.getFieldsValue(true)?.partner)))
          return formProps.onFinish?.(values);
        }}
      >
        <Row gutter={10}>
          <Space size={20} direction="vertical" style={{ width: "100%" }}>
            {renderProjectSteps()}
          </Space>
        </Row>
        <Row gutter={10}>
          <Col xs={24} lg={19}>
            {record?.status === projectStatus.rejected.value ||
            record?.status === projectStatus.contracts.value ||
            record?.status === projectStatus.price_offers.value ||
            record?.status === projectStatus.finished.value ||
            record?.status === projectStatus.provider_contracts.value ||
            record?.status === projectStatus.tenders.value ? (
              <>
                <Title level={5}>{t("projects.fields.name")}</Title>
                <Text>{record.name}</Text>
                <Title level={5}></Title>
              </>
            ) : (
              <Form.Item
                style={{
                  display: "grid",
                  marginBottom: 8,
                  marginRight: 50,
                  padding: 5,
                }}
                label={<Title level={5}>{t("projects.fields.name")}</Title>}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("defaultValidateMessages.required", {
                      name: "${label}",
                    }),
                  },
                ]}
              >
                <Input style={{width:'50%'}}/>
              </Form.Item>
            )}
          </Col>
          <Col xs={24} lg={19}>
            {record?.status === projectStatus.rejected.value ||
            record?.status === projectStatus.contracts.value ||
            record?.status === projectStatus.price_offers.value ||
            record?.status === projectStatus.finished.value ||
            record?.status === projectStatus.provider_contracts.value ||
            record?.status === projectStatus.tenders.value ? (
              <>
                <Title level={5}>{t("projects.fields.agent")}</Title>
                <Text>{`${record?.agent?.lastName} ${record?.agent?.firstName}`}</Text>
                <Title level={5}></Title>
              </>
            ) : (
              <Form.Item
                style={{
                  display: "grid",
                  marginBottom: 8,
                  marginRight: 50,
                  padding: 5,
                }}
                label={<Title level={5}>{t("projects.fields.agent")}</Title>}
                name={["agent", "@id"]}
                rules={[
                  {
                    required: true,
                    message: t("defaultValidateMessages.required", {
                      name: "${label}",
                    }),
                  },
                ]}
              >
                <Select
                  style={{width: '50%'}}
                  allowClear
                  {...userSelectProps}
                  options={userAllOptions.map((p) => ({
                    label: `${p.firstName} ${p.lastName}`,
                    value: p["@id"],
                  }))}
                />
              </Form.Item>
            )}
          </Col>
          <Col xs={24} lg={19}>
            {record?.status === projectStatus.rejected.value ||
            record?.status === projectStatus.contracts.value ||
            record?.status === projectStatus.price_offers.value ||
            record?.status === projectStatus.finished.value ||
            record?.status === projectStatus.provider_contracts.value ||
            record?.status === projectStatus.tenders.value ? (
              <>
                {record?.partner?.individual === true ? (
                  <Title level={5}>
                    {t("projects.fields.partner")}
                  </Title>
                ) : (
                  <Title level={5}>{t("projects.fields.partner")}</Title>
                )}
                <Text>
                <PartnerSelect value={record?.partner} disabled={true}/>
                </Text>
                <Title level={5}></Title>
              </>
            ) : (
              <Form.Item
                style={{
                  display: "grid",
                  marginBottom: 8,
                  marginRight: 50,
                  padding: 5,
                }}
                label={<Title level={5}>{t("projects.fields.partner")}</Title>}
                name={["partner"]}
                rules={[
                  {
                    required: true,
                    message: t("defaultValidateMessages.required", {
                      name: "${label}",
                    }),
                  },
                ]}
              >
                <PartnerSelect value={record?.partner}/>
              </Form.Item>
            )}
          </Col>
          
        </Row>

        <Tabs
          defaultActiveKey={`${record?.status}`}
          onChange={onTabChange}
          activeKey={activeKey}
        >
          <Tabs.TabPane
            tab={<span>{t("projects.statuses.technical_datasheets")}</span>}
            key={projectStatus.technical_datasheets.value}
          >
            {postData && <ProjectTechnicalDatasheetsTable record={postData} />}
          </Tabs.TabPane>

          <Tabs.TabPane
            disabled={
              record?.status === projectStatus.technical_datasheets.value
            }
            tab={<span>{t("price_offers.price_offers")}</span>}
            key={projectStatus.price_offers.value}
          >
            {postData && <ProjectPriceOffersTable record={postData} />}
          </Tabs.TabPane>

          <Tabs.TabPane
            disabled={
              record?.status === projectStatus.technical_datasheets.value ||
              record?.status === projectStatus.price_offers.value
            }
            tab={<span>{t("contracts.contracts")}</span>}
            key={projectStatus.contracts.value}
          >
            {postData && <ProjectContractsTable record={postData} />}
          </Tabs.TabPane>

          <Tabs.TabPane
            disabled={
              record?.status === projectStatus.technical_datasheets.value ||
              record?.status === projectStatus.price_offers.value ||
              record?.status === projectStatus.contracts.value
            }
            tab={<span>{t("provider_contracts.provider_contracts")}</span>}
            key={projectStatus.provider_contracts.value}
          >
            {postData && <ProjectProviderContractTable record={postData} />}
          </Tabs.TabPane>

          <Tabs.TabPane
            disabled={
              record?.status === projectStatus.technical_datasheets.value ||
              record?.status === projectStatus.price_offers.value ||
              record?.status === projectStatus.contracts.value ||
              record?.status === projectStatus.provider_contracts.value
            }
            tab={<span>{t("tenders.tenders")}</span>}
            key={projectStatus.tenders.value}
          >
            {postData && <ProjectTendersTable record={postData} />}
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Edit>
  );
};

const expandedRowRender = (record: IProject) => {
  return <ProjectTechnicalDatasheetsTable record={record} />;
};
