import { useEffect } from "react";
import { Refine, usePermissions, useGetIdentity } from "@pankod/refine-core";
import {
  notificationProvider,
  ConfigProvider,
  // LoginPage,
  ErrorComponent,
} from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
//import hu_HU from "antd/lib/locale/hu_HU";
import hu_HU from "utility/antdLocale_HU";
import dayjs from "dayjs";
import moment from "moment";
import "moment/locale/hu";

//import "styles/antd.less";
import "styles/solar.less";
import "dayjs/locale/hu";
//import simpleRestDataProvider from "@pankod/refine-simple-rest";
import simpleRestDataProvider from "./dataProvider";
//import { apiAuthProvider } from "./authProvider";
import apiAuthProvider from "authProvider";
import { PartnerCreate } from "./pages/partners/create";
import { CompaniesList } from "./pages/companies";
import { ProjectList, ProjectEdit, ProjectShow } from "pages/projects";
import { PartnerList } from "./pages/partners/list";
import { PartnerEdit } from "./pages/partners/edit";
import { PartnerShow } from "./pages/partners/show";
import { LocationsList, LocationEdit, LocationCreate } from "pages/locations";

import { newEnforcer } from "casbin.js";
import { model, adapter } from "accessControl";
import { ApiAccessControlProvider } from "./accessControlProvider";

import { DashboardPage } from "pages/dashboard";
import { LoginPage } from "./components/layout/login";

import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from "components/layout";
import { useTranslation } from "react-i18next";
import { API_URL } from "./constants";
import { TechnicalDatasheetShow } from "components/technicaldatasheets";
import { PriceOfferShow } from "components/priceOffers";
import { ContractShow } from "components/contracts";
import { ProviderContractShow } from "components/providerContract/show";
import { TendersShow } from "components/tenders/show";

function App() {
  const { t, i18n } = useTranslation();
  //const API_URL = "http://127.0.0.1:8000/api";

  //  const dataProvider = simpleRestDataProvider(API_URL);
  const { authProvider, axiosInstance } = apiAuthProvider(API_URL);
  const dataProvider = simpleRestDataProvider(API_URL, axiosInstance);
  //const { data: permissionsData } = usePermissions();
  const customAccessControlProvider = ApiAccessControlProvider();
  // const customAccessControlProvider = accessControlProvider

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const locale = i18nProvider.getLocale();

  useEffect(() => {
    if (locale === "hu") {
      moment.locale("hu");
      dayjs.locale("hu");
    } else {
      moment.locale("en");
      dayjs.locale("en");
    }
    document.documentElement.lang = locale;            
  }, [locale]);

  /*useEffect(() => {
    alert(JSON.stringify(permissionsData));
  }, [permissionsData]);
*/
  const role = localStorage.getItem("role") ?? "admin";
  
  return (
    <ConfigProvider locale={locale === "hu" ? hu_HU : undefined}>
      <Refine
        routerProvider={routerProvider}
        dataProvider={dataProvider}
        authProvider={authProvider}
        Title={Title}
        Header={Header}
        Sider={Sider}
        Footer={Footer}
        Layout={Layout}
        OffLayoutArea={OffLayoutArea}
        DashboardPage={DashboardPage}
        i18nProvider={i18nProvider}
        accessControlProvider={customAccessControlProvider}
        resources={[
          {
            name: "base_datas",
            options: {
              label: t("menu.base_datas"),
            },
          },
          {
            parentName: "base_datas",
            name: "partners",
            list: PartnerList,
            create: PartnerCreate,
            edit: PartnerEdit,
            show: PartnerShow,
            canDelete: true,
          },
          {
            parentName: "base_datas",
            name: "locations",
            list: LocationsList,
            edit: LocationEdit,
            create: LocationCreate,
            canDelete: false,
          },
          {
            parentName: "base_datas",
            name: "companies",
            list: CompaniesList,
          },
          {
            name: "projects",
            list: ProjectList,
            edit: ProjectEdit,
            show: ProjectShow,
          },
          {
            name: "technical_datasheets",
            show: TechnicalDatasheetShow,
          },
          {
            name: "price_offers",
            show: PriceOfferShow,
          },
          {
            name: "contracts",
            show: ContractShow,
          },
          {
            name: "provider_contracts",
            show: ProviderContractShow,
          },
          {
            name: "tenders",
            show: TendersShow,
          },
        ]}
        notificationProvider={notificationProvider}
        LoginPage={LoginPage}
        catchAll={<ErrorComponent />}
      ></Refine>
    </ConfigProvider>
  );
}

export default App;
