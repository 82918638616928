import {
  Col,
  DateField,
  EditButton,
  FileField,
  List,
  Modal,
  RefreshButton,
  Row,
  Show,
  ShowButton,
  Space,
  Table,
  Typography,
  useDrawerForm,
  useTable,
} from "@pankod/refine-antd";
import { useApiUrl, useShow, useTranslate } from "@pankod/refine-core";
import { Statuses } from "components/common";
import { CreateTenders, EditTenders } from "components/tenders";
import { IProject } from "interfaces/project";
import { ITenders } from "interfaces/tenders";
import dayjs from "dayjs";
import { projectStatus } from "constants/project";
import { useState } from "react";

export const ProjectTendersTable: React.FC<{ record: IProject }> = ({
  record,
}) => {
  const API_URL = useApiUrl();
  const t = useTranslate();
  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow,
  } = useDrawerForm<ITenders>({
    action: "create",
    resource: "tenders",
    redirect: false,
    //defaultFormValues:{ name: "hhhhh"}
  });

  const { Title, Text } = Typography;

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow,
  } = useDrawerForm<ITenders>({
    action: "edit",
    resource: "tenders",
    redirect: false,
  });

  const [visible, setVisible] = useState(false);

  const { tableProps: tendersableProps } = useTable<ITenders>({
    resource: "tenders",
    permanentFilter: [
      {
        field: "project.id",
        operator: "eq",
        value: record.id,
      },
    ],
    syncWithLocation: false,
  });

  const { queryResult, showId, setShowId } = useShow<ITenders>({
    resource: "tenders",
  });
  const { data, isLoading } = queryResult;
  const record__ = data?.data;

  return (
    <>
      <List
        title={t("tenders.tenders")}
        createButtonProps={{
          onClick: () => {
            createShow();
          },
          disabled:
            record?.status === projectStatus.finished.value ||
            record?.status === projectStatus.rejected.value,
        }}
      >
        <Table {...tendersableProps} rowKey="id">
          <Table.Column
            key="name"
            dataIndex="name"
            title={t("tenders.fields.name")}
          />

          <Table.Column<ITenders>
            dataIndex="status"
            title={t("price_offers.fields.status")}
            render={(value) => {
              return <Statuses status={value} />;
            }}
          />
          <Table.Column<ITenders>
            title={t("table.actions")}
            dataIndex="actions"
            render={(_, rec) =>
              record?.status === projectStatus.tenders.value ? (
                <Space>
                  <EditButton onClick={() => editShow(rec.id)}>
                    {t("projects.buttons.editTenders")}
                  </EditButton>
                </Space>
              ) : (
                <Space>
                  <ShowButton
                    size="small"
                    resourceNameOrRouteName="tenders"
                    recordItemId={rec.id}
                    onClick={() => {
                      setShowId(rec.id);
                      setVisible(true);
                    }}
                  />
                </Space>
              )
            }
          />
        </Table>
      </List>
      <Modal visible={visible} closable={false} footer={false} width={800}>
        <Show
          isLoading={isLoading}
          pageHeaderProps={{
            onBack: () => setVisible(false),
            extra: <RefreshButton hidden/>
          }}
          resource="tenders"
          recordItemId={showId}
        >
          <Row gutter={20}>
            <Col xs={24} lg={30}>
              <Row gutter={10}>
                <Col xs={24} lg={12}>
                  <Title level={5}>{t("tenders.fields.name")}</Title>
                  <Text>{record__?.name}</Text>

                  <Title level={5}>{t("tenders.fields.status")}</Title>
                  <Text>{t(`statusTypes.${record__?.status}`)}</Text>

                  <Title level={5}>{t("tenders.fields.date")}</Title>
                  <DateField value={record__?.startDate} format="LL" />
                </Col>
                <Col xs={24} lg={12}>
                  {/*<FileField src={record.image[0].url} />*/}

                  <Title level={5}>{t("tenders.fields.description")}</Title>
                  <Text>{record__?.description}</Text>

                  <Title level={5}>{t("tenders.fields.type")}</Title>
                  <Text>{record__?.type}</Text>

                  <Title level={5}>{t("tenders.fields.file")}</Title>
                  <Row gutter={10}>
                  {record__?.images?.map((data: any) => {
                    return (
                      <Col xs={24} lg={12}>
                        <FileField
                          target="_blank"
                          title={data?.name}
                          src={data?.url}
                        />
                      </Col>
                    );
                  })}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Show>
      </Modal>
      <CreateTenders
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
        initialValues={{
          project: `${API_URL}/projects/${record.id}`,
          status: "live",
        }}
      />

      <EditTenders
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
      />
    </>
  );
};
