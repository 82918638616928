import {
  List,
  Table,
  useTable,
  Modal,
  Show,
  ShowButton,
  Typography,
  Col,
  Row,
  FileField,
  Button,
  DateField,
  RefreshButton,
} from "@pankod/refine-antd";
import { useApiUrl, useTranslate, useShow } from "@pankod/refine-core";
import { Statuses } from "components/common";
import { IProject } from "interfaces/project";
import { IContract } from "interfaces/contract";
import { useState } from "react";

const { Title, Text } = Typography;

export const ProjectContractsTable: React.FC<{ record: IProject }> = ({
  record: project,
}) => {
  const API_URL = useApiUrl();
  const t = useTranslate();
  const [visible, setVisible] = useState(false);

  const { tableProps: contractsableProps } = useTable<IContract>({
    resource: "contracts",
    permanentFilter: [
      {
        field: "project.id",
        operator: "eq",
        value: project.id,
      },
    ],
    syncWithLocation: false,
  });

  const { queryResult, showId, setShowId } = useShow<IContract>({
    resource: "contracts",
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;
  return (
    <>
      <List title={t("contracts.contracts")}>
        <Table {...contractsableProps} rowKey="id">
          <Table.Column
            key="description"
            dataIndex="description"
            title={t("contracts.fields.name")}
          />

          <Table.Column<IContract>
            dataIndex="status"
            title={t("contracts.fields.status")}
            render={(value) => {
              return <Statuses status={value} />;
            }}
          />
          <Table.Column<IContract>
            title={t("table.actions")}
            dataIndex="actions"
            render={(_, record) => (
              <ShowButton
                size="small"
                recordItemId={record.id}
                onClick={() => {
                  setShowId(record.id);
                  setVisible(true);
                }}
              />
            )}
          />
        </Table>
      </List>
      <Modal visible={visible} closable={false} footer={false} width={800}>
        <Show
          isLoading={isLoading}
          pageHeaderProps={{
            onBack: () => setVisible(false),
            extra: <RefreshButton hidden/>
          }}
          resource="contracts"
          recordItemId={showId}
        >
          <Row gutter={20}>
            <Col xs={24} lg={30}>
              <Row gutter={10}>
                <Col xs={24} lg={12}>
                  <Title level={5}>{t("contracts.fields.description")}</Title>
                  <Text>{record?.description}</Text>

                  <Title level={5}>
                    {t("contracts.fields.nameOfContractor")}
                  </Title>
                  <Text>{record?.nameOfContractor}</Text>

                  <Title level={5}>
                    {t("contracts.fields.contractorPosition")}
                  </Title>
                  <Text>{record?.contractorPosition}</Text>
                </Col>
                <Col xs={24} lg={12}>
                  <Title level={5}>{t("contracts.fields.status")}</Title>
                  <Text>{t(`statusTypes.${record?.status}`)}</Text>

                  <Title level={5}>{t("contracts.fields.date")}</Title>
                  <DateField value={record?.date} format="LL" />

                  <Title level={5}>{t("contracts.fields.file")}</Title>
                  <Row gutter={10}>
                  {record?.images?.map((data: any) => {
                    return (
                      <Col xs={24} lg={12}>
                        <FileField
                          target="_blank"
                          title={data?.name}
                          src={data?.url}
                        />
                      </Col>
                    );
                  })}
                  {/*<FileField src={record.image[0].url} />*/}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Show>
      </Modal>
    </>
  );
};
