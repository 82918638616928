import {
  List,
  Table,
  useTable,
  Modal,
  Show,
  ShowButton,
  Typography,
  Col,
  Row,
  FileField,
  Button,
  DateField,
  Tag,
  TextField,
  InputNumber,
  NumberField,
  RefreshButton,
} from "@pankod/refine-antd";
import { useApiUrl, useTranslate, useShow } from "@pankod/refine-core";
import { TechnicalDatasheetStatus } from "components/technicalDatasheetStatus";
import { amperMenu } from "constants/technicalDatasheet";
import { IProject } from "interfaces/project";
import { ITechnicalDatasheet } from "interfaces/technicaldatasheet";
import { useState } from "react";

const { Title, Text } = Typography;

export const ProjectTechnicalDatasheetsTable: React.FC<{
  record: IProject;
}> = ({ record: project }) => {
  const API_URL = useApiUrl();
  const t = useTranslate();
  const [visible, setVisible] = useState(false);

  const { tableProps: technical_datasheetsableProps } =
    useTable<ITechnicalDatasheet>({
      resource: "technical_datasheets",
      permanentFilter: [
        {
          field: "project.id",
          operator: "eq",
          value: project.id,
        },
      ],
      syncWithLocation: false,
    });

  const { queryResult, showId, setShowId } = useShow<ITechnicalDatasheet>({
    resource: "technical_datasheets",
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;
  return (
    <>
      <List title={t("technical_datasheets.technical_datasheets")}>
        <Table {...technical_datasheetsableProps} rowKey="id">
          <Table.Column
            key="name"
            dataIndex="name"
            title={t("technical_datasheets.fields.name")}
          />

          <Table.Column<ITechnicalDatasheet>
            dataIndex="status"
            title={t("technical_datasheets.fields.status")}
            render={(value) => {
              return <TechnicalDatasheetStatus status={value} />;
            }}
          />
          <Table.Column<ITechnicalDatasheet>
            title={t("table.actions")}
            dataIndex="actions"
            render={(_, record) => (
              <ShowButton
                size="small"
                recordItemId={record.id}
                onClick={() => {
                  setShowId(record.id);
                  setVisible(true);
                }}
              />
            )}
          />
        </Table>
      </List>
      <Modal visible={visible} closable={false} footer={false} width={900}>
        <Show
          isLoading={isLoading}
          pageHeaderProps={{
            onBack: () => setVisible(false),
            extra: <RefreshButton hidden/>
          }}
          resource="technical_datasheets"
          recordItemId={showId}
        >
          <Row gutter={20}>
            <Col xs={24} lg={30}>
              <Row gutter={10}>
                <Col xs={24} lg={12}>
                  <Title level={5}>
                    {t("technical_datasheets.fields.name")}
                  </Title>
                  <Text>{record?.name}</Text>

                  <Title level={5}>
                    {t("technical_datasheets.fields.addressId")}
                  </Title>
                  <Text>{record?.address}</Text>

                  <Title level={5}>
                    {t("technical_datasheets.fields.providerPartnerNumber")}
                  </Title>
                  <Text>{record?.providerPartnerNumber}</Text>

                  <Title level={5}>
                    {t("technical_datasheets.fields.providerContractNumber")}
                  </Title>
                  <Text>{record?.providerContractNumber}</Text>

                  <Title level={5}>
                    {t("technical_datasheets.fields.status")}
                  </Title>
                  <Text>
                    {t(`technical_datasheets.statusTypes.${record?.status}`)}
                  </Text>

                  <Title level={5}>
                    {t("technical_datasheets.fields.providerType")}
                  </Title>
                  <Text>
                    <Tag>{record?.providerType}</Tag>
                  </Text>

                  <Title level={5}>
                    {t("technical_datasheets.fields.podNumber")}
                  </Title>
                  <Text>{record?.podNumber}</Text>

                  <Title level={5}>
                    {t("technical_datasheets.fields.providerMeasuringClock")}
                  </Title>
                  <NumberField
                    value={record?.providerMeasuringClock ?? 0}
                    options={{
                      notation: "standard",
                    }}
                  />
                </Col>

                <Col xs={24} lg={12}>
                  <Title level={5}>
                    {t("technical_datasheets.fields.phaseR")}
                  </Title>
                  <Text>
                    {amperMenu.find((el) => el.value === record?.phaseR)?.label}
                  </Text>

                  <Title level={5}>
                    {t("technical_datasheets.fields.phaseS")}
                  </Title>
                  <Text>
                    {amperMenu.find((el) => el.value === record?.phaseS)?.label}
                  </Text>

                  <Title level={5}>
                    {t("technical_datasheets.fields.phaseT")}
                  </Title>
                  <Text>
                    {amperMenu.find((el) => el.value === record?.phaseT)?.label}
                  </Text>

                  <Title level={5}>
                    {t("technical_datasheets.fields.plannedConsumptionkWh")}
                  </Title>
                  <NumberField
                    value={record?.plannedConsumptionkWh ?? 0}
                    options={{
                      notation: "standard",
                    }}
                  />

                  <Title level={5}>
                    {t("technical_datasheets.fields.yearlyConsumptionFt")}
                  </Title>
                  <NumberField
                    value={record?.yearlyConsumptionFt ?? 0}
                    options={{
                      notation: "standard",
                    }}
                  />

                  <Title level={5}>
                    {t("technical_datasheets.fields.yearlyConsumptionkWh")}
                  </Title>
                  <NumberField
                    value={record?.yearlyConsumptionkWh ?? 0}
                    options={{
                      notation: "standard",
                    }}
                  />

                  <Title level={5}>
                    {t("technical_datasheets.fields.plannedConsumptionFt")}
                  </Title>
                  <NumberField
                    value={record?.plannedConsumptionFt ?? 0}
                    options={{
                      notation: "standard",
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Show>
      </Modal>
    </>
  );
};
