import { useEffect, useMemo } from "react";
import {
  useApiUrl,
  useCustom,
  useGetIdentity,
  useList,
  useShow,
  useTranslate,
} from "@pankod/refine-core";
import { Typography } from "@pankod/refine-antd";
import { IProject } from "interfaces/project";
import "./style.less";
import { IUser } from "interfaces/user";

export const ProjectCounter: React.FC = () => {
  const t = useTranslate();
  const API_URL = useApiUrl();
  const { data: projectData } = useList<IProject>({
    resource: "projects",
    config: {
      filters: [
        {
          field: "paginate",
          operator: "eq",
          value: false,
        },
      ],
    },
  });

  const { data: user } = useGetIdentity();
  const { setShowId: setUserShowId, queryResult: companyQuery } =
    useShow<IUser>({
      resource: "users",
      id: user?.id,
    });
  const { data: companyData } = companyQuery;
  const companyRecord = companyData?.data;

  useEffect(() => {
    setUserShowId(user?.id);
  }, [user]);
  /*   const url = `${API_URL}/projectCounter`;
    const { data, isLoading } = useCustom<{
        total: number;
        trend: number;
    }>({ url, method: "get" });
*/
  const { Text, Title } = Typography;

  return (
    <div className="projectCounter-wrapper">
      <div className="header">
        <Title level={2} className="header__title">
          {t("dashboard.projectCounter")}
        </Title>

        <div className="header__numbers">
          <Text strong>
            {companyRecord?.maxProjectNumber ?
            `${companyRecord?.maxProjectNumber}/${projectData?.data.length ?? 0}`
            :`${projectData?.data.length ?? 0}`}
          </Text>
        </div>
      </div>
    </div>
  );
};
