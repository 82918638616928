import { useTranslate } from "@pankod/refine-core";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "styles/phone.style.css";
import {
  useForm,
  Form,
  Input,
  Steps,
  Select,
  Edit,
  useSelect,
  InputNumber,
  useStepsForm,
  Button,
  SaveButton,
  Checkbox,
  Col,
  Row,
  Space,
  getValueFromEvent,
  ListButton,
  ShowButton,
} from "@pankod/refine-antd";

import { IPartner } from "interfaces/partner";
import { ILocation } from "interfaces/location";
import { publicAreaTypes, addressTypes } from "constants/address";
import { payinghMethod } from "constants/partner";

import { formatStrategyValues } from "rc-tree-select/lib/utils/strategyUtil";

const { Step } = Steps;

export const PartnerEdit: React.FC = () => {
  const {
    formProps,
    saveButtonProps,
    current,
    gotoStep,
    stepsProps,
    queryResult,
  } = useStepsForm<IPartner>();
  const t = useTranslate();

  const {
    selectProps: locationSelectProps,
    queryResult: locationQueryResult,
    defaultValueQueryResult: locationDefaultValueQueryResult,
  } = useSelect<ILocation>({
    resource: "locations",
    filters: [
      {
        field: "paginate",
        operator: "eq",
        value: false,
      },
    ],
    onSearch: (value) => [
      {
        field: "searchName",
        operator: "eq",
        value,
      },
    ],
  });

  const allOptions = [
    ...(locationQueryResult.data?.data || []),
    ...(locationDefaultValueQueryResult.data?.data || []),
  ];

  const formList = [
    <>
      <Row gutter={20}>
        <Col xs={24} lg={16}>
          <Row gutter={10}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t("partners.fields.name")}
                name="name"
                rules={[
                  /*
                  {
                    pattern: new RegExp(
                      /^[a-zA-Z]+$/i
                    ),
                    message: t("defaultValidateMessages.notValidLastName")
                  },*/
                  {
                    required: true,
                    message: t("defaultValidateMessages.required", {
                      name: "${label}",
                    }),
                  },
                ]}
              >
                <Input tabIndex={1} />
              </Form.Item>

              <Form.Item
                label={t("partners.fields.email")}
                name="email"
                rules={[
                  {
                    type: "email",
                    message: t("defaultValidateMessages.notValidEmail"),
                  },
                  {
                    required: true,
                    message: t("defaultValidateMessages.required", {
                      name: "${label}",
                    }),
                  },
                ]}
              >
                <Input tabIndex={3} />
              </Form.Item>

              <Form.Item
                shouldUpdate
                label={t("partners.fields.individual")}
                name="individual"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>

              <Form.Item
                label={t("partners.fields.type")}
                name="type"
                hidden={true}
              >
                <InputNumber defaultValue={1} />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
            
              <Form.Item
                label={t("partners.fields.phoneNumber")}
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: t("defaultValidateMessages.required", {
                      name: "${label}",
                    }),
                  },

                  {
                    validator: (_, value) =>
                      value && isValidPhoneNumber(value)
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              t("defaultValidateMessages.notValidPhone")
                            )
                          ),
                  },
                ]}
              >
                <PhoneInput
                  defaultCountry="HU"
                  tabIndex={4}
                  onChange={(value) => value}
                />
              </Form.Item>
              <Form.Item dependencies={["individual"]}>
                {({ getFieldValue }) => {
                  return (
                    getFieldValue("individual") && (
                      <>
                        <Form.Item
                          label={t("partners.fields.mothersName")}
                          name="mothersName"
                          rules={[
                            /*
                  {
                    pattern: new RegExp(
                      /^[a-zA-Z]+$/i
                    ),
                    message: t("defaultValidateMessages.notValidMothersName")
                  },
                  */
                            {
                              message: t("defaultValidateMessages.required", {
                                name: "${label}",
                              }),
                            },
                          ]}
                        >
                          <Input tabIndex={5} />
                        </Form.Item>
                      </>
                    )
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </>,

    <Row key="relations" gutter={20}>
      <Col xs={24} lg={9}>
        <Form.Item
          label={t("partners.fields.payingMethodId")}
          name="payingMethodId"
          rules={[
            {
              required: true,
              message: t("defaultValidateMessages.required", {
                name: "${label}",
              }),
            },
          ]}
        >
          <Select
            tabIndex={1}
            options={payinghMethod.map((p) => ({
              label: t(`${p.label}`),
              value: p.value,
            }))}
            placeholder={t("buttons.pleaseSelect")}
          />
        </Form.Item>

        <Form.Item dependencies={["individual"]}>
          {({ getFieldValue }) => {
            return (
              getFieldValue("individual") && (
                <>
                  <Form.Item
                    label={t("partners.fields.taxIdentificationNumber")}
                    name="taxIdentificationNumber"
                    rules={[
                      {
                        required: true,
                        message: t("defaultValidateMessages.required", {
                          name: "${label}",
                        }),
                      },
                    ]}
                  >
                      <Input tabIndex={3}
//                    style={{width:'100%'}}
                    maxLength={10}
                     
                     />
                  </Form.Item>

                  <Form.Item
                    label={t("partners.fields.identityNumber")}
                    name="identityNumber"
                    rules={[
                      {
                        required: true,
                        message: t("defaultValidateMessages.required", {
                          name: "${label}",
                        }),
                      },
                    ]}
                  >
                    <Input tabIndex={5} />
                  </Form.Item>
                </>
              )
            );
          }}
        </Form.Item>

        <Form.Item dependencies={["individual"]}>
          {({ getFieldValue }) => {
            return (
              !getFieldValue("individual") && (
                <>
                  <Form.Item
                    label={t("partners.fields.companyNumber")}
                    name="companyNumber"
                    rules={[
                      {
                        message: t("defaultValidateMessages.required", {
                          name: "${label}",
                        }),
                      },
                    ]}
                  >
                    <Input tabIndex={3} />
                  </Form.Item>
                  <Form.Item
                    label={t("partners.fields.euTaxNumber")}
                    name="euTaxNumber"
                    rules={[
                      {
                        required: true,
                        message: t("defaultValidateMessages.required", {
                          name: "${label}",
                        }),
                      },
                    ]}
                  >
                    <Input tabIndex={4} />
                  </Form.Item>
                  <Form.Item
                    label={t("partners.fields.taxNumber")}
                    name="taxNumber"
                    rules={[
                      {
                        required: true,
                        message: t("defaultValidateMessages.required", {
                          name: "${label}",
                        }),
                      },
                    ]}
                  >
                    <Input tabIndex={5} />
                  </Form.Item>
                </>
              )
            );
          }}
        </Form.Item>
      </Col>
      <Col xs={24} lg={9}>
        <Form.Item dependencies={["payingMethodId"]}>
          {({ getFieldValue }) => {
            return (
              getFieldValue("payingMethodId") === 1 && (
                <>
                  <Form.Item
                    label={t("partners.fields.bankAccount")}
                    name="bankAccount"
                    rules={[
                      {
                        message: t("defaultValidateMessages.required", {
                          name: "${label}",
                        }),
                      },
                    ]}
                  >
                    <Input tabIndex={2} />
                  </Form.Item>
                </>
              )
            );
          }}
        </Form.Item>
      </Col>
    </Row>,

    <Row gutter={20}>
      <Col span={20} xs={24} lg={30}>
        <Form.List
          name="partnerAddresses"
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 1) {
                  return Promise.reject(
                    new Error(
                      t("defaultValidateMessages.requiredAtLeast", {
                        count: 1,
                        name: t("partners.fields.partnerAddress"),
                      })
                    )
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field) => (
                <Space
                  key={field.key}
                  style={{
                    display: "grid",
                    marginBottom: 8,
                    marginLeft: 50,
                    marginRight: 50,
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...field}
                    name={[field.name, "id"]}
                    fieldKey={[field.key, "id"]}
                    hidden
                  >
                    <Input />
                  </Form.Item>
                  <Row gutter={20}>
                    <Col xs={24} lg={7}>
                      <Form.Item
                        {...field}
                        label={t("addresses.fields.type")}
                        name={[field.name, "type"]}
                        //rules={[{ required: true, message: "Missing type" }]}
                        rules={[
                          {
                            required: true,
                            message: t("defaultValidateMessages.required", {
                              name: "${label}",
                            }),
                          },
                        ]}
                      >
                        <Select
                          tabIndex={1}
                          options={addressTypes.map((c) => ({
                            label: t(`addresses.enum.addressTypes.${c.label}`),
                            value: c.value,
                            //    value: `${API_URL}/countries/${c.id}`,
                          }))}
                        />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        hidden
                        label={t("addresses.fields.location")}
                        name={[field.name, "address", "id"]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        label={t("addresses.fields.publicAreaName")}
                        name={[field.name, "address", "publicAreaName"]}
                        rules={[
                          {
                            required: true,
                            message: t("defaultValidateMessages.required", {
                              name: "${label}",
                            }),
                          },
                        ]}
                      >
                        <Input tabIndex={4} />
                      </Form.Item>
                    </Col>

                    <Col xs={24} lg={7}>
                      <Form.Item
                        {...field}
                        label={t("addresses.fields.postcode")}
                        name={[field.name, "address", "location"]}
                        /* rules={[
                          { required: true, message: "Missing location" },
                        ]}
                        */
                        rules={[
                          {
                            required: true,
                            message: t("defaultValidateMessages.required", {
                              name: "${label}",
                            }),
                          },
                        ]}
                      >
                        <Select
                          tabIndex={2}
                          {...locationSelectProps}
                          options={allOptions.map((p) => ({
                            label: `${p.postcode}-${p.name}`,
                            value: p["@id"],
                          }))}
                        />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        label={t("addresses.fields.publicAreaType")}
                        name={[field.name, "address", "publicAreaType"]}
                      >
                        <Select
                          tabIndex={5}
                          options={publicAreaTypes.map((c) => ({
                            label: t(
                              `addresses.enum.publicAreaTypes.${c.label}`
                            ),
                            value: c.value,
                          }))}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} lg={7}>
                      <Form.Item
                        {...field}
                        label={t("addresses.fields.district")}
                        name={[field.name, "address", "district"]}
                      >
                        <Input tabIndex={3} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={20}>
                    <Col xs={24} lg={7}>
                      <Form.Item
                        {...field}
                        label={t("addresses.fields.houseNumber")}
                        name={[field.name, "address", "houseNumber"]}
                      >
                        <Input tabIndex={6} />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        label={t("addresses.fields.floor")}
                        name={[field.name, "address", "floor"]}
                      >
                        <Input tabIndex={9} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={7}>
                      <Form.Item
                        {...field}
                        label={t("addresses.fields.building")}
                        name={[field.name, "address", "building"]}
                      >
                        <Input tabIndex={7} />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        label={t("addresses.fields.door")}
                        name={[field.name, "address", "door"]}
                      >
                        <Input tabIndex={10} />
                      </Form.Item>
                    </Col>

                    <Col xs={24} lg={7}>
                      <Form.Item
                        {...field}
                        label={t("addresses.fields.staircase")}
                        name={[field.name, "address", "staircase"]}
                      >
                        <Input tabIndex={8} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  {t("addresses.titles.create")}
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Col>
    </Row>,
  ];
  return (
    <Edit
      pageHeaderProps={{extra:
      <>
        <ListButton size="middle" hideText/>
        <ShowButton size="middle" hideText/>
      </>}}
      isLoading={queryResult?.isFetching}
      saveButtonProps={saveButtonProps}
      actionButtons={
        <>
          {current > 0 && (
            <Button
              onClick={() => {
                gotoStep(current - 1);
              }}
            >
              {t("buttons.previous")}
            </Button>
          )}
          {current < formList.length - 1 && (
            <Button
              onClick={() => {
                gotoStep(current + 1);
              }}
            >
              {t("buttons.next")}
            </Button>
          )}
          {current === formList.length - 1 && (
            <SaveButton {...saveButtonProps} />
          )}
        </>
      }
    >
      <Steps {...stepsProps}>
        <Step title={t("partners.titles.personalData")} />
        <Step />
        <Step title={t("partners.titles.addressDetails")} />
      </Steps>

      <Form {...formProps} layout="vertical" style={{ marginTop: 30 }}>
        <>
          {formList[current]}
          {/*<pre>{JSON.stringify(formProps.form?.getFieldsValue(), null, 2)}</pre>*/}
        </>
      </Form>
    </Edit>
  );
};
