import { useTranslate } from "@pankod/refine-core";

import { Tag } from "@pankod/refine-antd";

type StatusProps = {
    status: "live" | "accepted" | "closed";
};

export const Statuses: React.FC<StatusProps> = ({ status }) => {
    const t = useTranslate();
    let color;

    switch (status) {
        case "live":
            color = "orange";
            break;
        case "accepted":
            color = "cyan";
            break;
        case "closed":
            color = "green";
            break;
    }

    return <Tag color={color}>{t(`statusTypes.${status}`)}</Tag>;
};