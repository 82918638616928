import { UploadFile } from "@pankod/refine-antd";

interface UploadResponse {
  id?: string;
  url: string;
}
interface EventArgs<T = UploadResponse> {
  file: UploadFile<T>;
  fileList: Array<UploadFile<T>>;
}

export const normalizeFile = (event: EventArgs) => {
 
  const { fileList } = event;

  return fileList.map((item) => {
   // alert(JSON.stringify(item))
    const { uid, name, type, size, response, percent, status } = item;
    return {
      id: response?.id || uid,        
      uid,
      name,
      url: item.url || response?.url,
      type,
      size,
      percent,
      status,
    };
  });
};

export const mediaUploadMapper = (params: any) => {
    //alert(JSON.stringify(params));
  Object.keys(params).map((item) => {
    if (params[item]) {
       
      const param = params[item].fileList;
      const isMediaField = Array.isArray(param);
      //alert(JSON.stringify(param));
      // if (isMediaField) {
      if (param) {
        const ids = [];
        for (const item of param) {
          //alert(JSON.stringify(item));
          if (item.response) {
            if (Array.isArray(item.response)) {
                // alert(JSON.stringify(item.response));
              for (const response of item.response) {
                //ids.push(response.id);
                ids.push(`/api/media_objects/${response.id ?? response.uid}`);
              }
            } else {              
              ids.push(`/api/media_objects/${item.response.id ?? item.response.uid}`);
            }
          } else {
            ids.push(`/api/media_objects/${item.id ?? item.uid}`);
          }
        }
        params[item] = ids;
        //alert(JSON.stringify(ids));
      }
    }
  });
  return params;
};

export const getValueProps = (data: any, imageUrl: string) => {    
  if (!data) {
    return { fileList: [] };
  }

  return {
    file: data.file,
    fileList:
      data.fileList ??
      (Array.isArray(data) ? data : [...data]).map((item: any) => {
        const file: any = {
          name: item.name,
          percent: item.percent,
          size: item.size,
          status: item.status,
          type: item.mime || item.type,
          uid: item.id,
        };
      //  alert(JSON.stringify(item))
        if (item.url) {
          file.url = `${imageUrl}${item.url}`;
        }

        return file;
      }),
  };
};
